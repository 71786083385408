import React from 'react';
function getSize(props){
  const {xs,sm,md,lg,xl} = props;
  return xl ? 'xl' : 
    lg ? 'lg' :
    md ? 'md' :
    sm ? 'sm' :
    xs ? 'xs' : '' 
}
class ButtonGroup extends React.Component {
  renderAddOn(){
    const {addOn,isJustified} = this.props;
    if(!addOn) return null;
    if(isJustified){
      return (<div className="btn-group input-group-addon">
        {addOn}
      </div>)
    }else{
      let style ={width:"inherit",cusor:"inherit",pointerEvents: "none"};
      return (<button style={style} className="btn input-group-addon">
          {addOn}
      </button>)
    }
  }
  renderButtons(){
    let {
      value: currentValue,
      isJustified,
      buttonClass = 'btn btn-primary',
      buttonProps = {},
      name = "",
      size = '',
      disabled,
      selectOptions,
      onChange
    } = this.props;
    size = size || getSize(this.props);
    return selectOptions.map((o)=>{
      let value = o.value || o
      let isSelected = (currentValue === value )
      const props = {
        key:value,
        value,
        name,
        onClick:(isSelected || disabled) ? null : onChange,
        className:buttonClass+((isSelected)?' active' : '')+((disabled)?' disabled':'')
      }
      if(size){
        props.className+= ' btn-'+size;
      }
      let btn = (<button {...props} {...buttonProps}>
          {this.renderIcon(o.icon)}
          {o.text || o}
      </button>)
      if(isJustified){
        return (<div key={value} className="btn-group">{btn}</div>)
      }else{
        return btn
      }
    })
  }
  renderIcon(icon){
    if(!icon) return null
    return (<span className={"fa fa-"+icon} style={{paddingRight: "5px"}}></span>)
  }
  render(){
    const {
      groupProps={},
      isVertical,
      size = false,
      isJustified = false
    } = this.props;
    let className = (!isVertical) ? "btn-group" : "btn-group-vertical"
    if(size){
      className+=' btn-group-'+size;
    }
    if(isJustified){
      className+=' btn-group-justified'
    }
    return (<div className={className} {...groupProps}>
        {this.renderAddOn()}
        {this.renderButtons()}
    </div>)
  }
}

export default ButtonGroup





// import store from '../reduxStore'
function initState(){
	return {
		notifications:[]
	}
}
const TIMEOUT = 5000;

function addSuccess(state,message){
	return addAlert(state,{
		type:'success',
		text:message
	});
}
function addError(state,error){
	return addAlert(state,{
		type:'danger',
		text:error
	});
}
function hideAlert(state,id){
	let notifications = state.notifications.map(a=>{
		if(a.id === id){
			a.hidden = true;
		}
		return a
	});
	return {...state, notifications}
}
function addAlert(state,data){
	let created = new Date();
	let id = created.valueOf()
	data = Object.assign(data,{
		id,created,hidden:false,
		key:data.text+id
	});
	let notifications = [data].concat( state.notifications.filter(a=>a.key !== data.key) );
	if(notifications[3]){
		notifications[3].hidden = true
	}
	// THis is a bit hacky - TODO
	setTimeout(function(){
		//store.dispatch({type:'hideAlert',data:data.id})
	},TIMEOUT)
	return {...state, notifications}
}




function notifications(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'addAlert':
			return addAlert(state,data)
		case 'addError':
			return addError(state,data)
		case 'addSuccess':
			return addSuccess(state,data)
		case 'hideAlert':
			return hideAlert(state,data)
		default:
			return state
	}
}	

export default notifications







//@ts-ignore
const dl = window.dataLayer || [];

export function event(event: string, data: Record<string, any> = {}){
	dl.push({ ...data, event })
}

export function push(data: Record<string, any>){
	dl.push(data);
}
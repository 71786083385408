import React, { Component } from "react";
const FallbackPromise = import(/* webpackChunkName: "Fallback"*/ '../routes/Fallback').then(function (res) {
  return res.default
}).catch(e => null)
function retryImport(fn, retriesLeft = 2, interval = 1500) {
  return new Promise((resolve, reject) => {
    fn().then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            return reject(error);
          }
          // Passing on "reject" is the important part
          retryImport(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    state = {
      component: null
    }
    async componentDidMount() {
      const Fallback = await FallbackPromise
      let state = {
        Fallback
      }
      try {
        const { default: component } = await retryImport(importComponent);
        state.component = component
      } catch (error) {
        state.error = error
      }
      if (this.unmounting) {
        return
      }
      this.setState(state)
    }
    componentWillUnmount() {
      this.unmounting = true
    }
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error: error.message };
    }
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo)
    }
    render() {
      const { component: C, error, Fallback } = this.state;
      if (error) {
        console.log(error)
        return Fallback ? <Fallback {...this.props} /> : null;
      }
      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
const regularExpressions = {
	URL:/[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/i,
	// eslint-disable-next-line
	EMAIL:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	BUILDER_VALUE:/^[a-z\d-]+$/,
	GA_UTM:/^(utm_source|utm_medium|utm_campaign|utm_term|utm_content)$/
};

export default function REGS(reg){
	if(!regularExpressions[reg]) throw new Error('No Such Regular Expression '+reg)
	return regularExpressions[reg]
}
import React from 'react';
import { Link } from 'react-router-dom'
import { useHistory, useRouteMatch, useLocation } from "react-router";
import AccountTool from '../components/AccountTool'
import { Btn, Fa } from '../components/bootstrap';
import '../css/navbars.css';
import { useAppSelector } from '../redux/hooks';
const NAV_IN_S = {
  zIndex: 1030,
  minHeight: "84px",
  backgroundColor: 'white',
  fontSize: '12px',
  marginBottom: '0px'
}
const NAV_OUT_S = {
  zIndex: 2,
  minHeight: "84px",
  backgroundColor: 'white',
  color: 'black',
  fontSize: '12px',
  marginBottom: '0px'
}
function Logo(props) {
  const src = 'https://utm-builder.com/wp-content/uploads/2019/11/cropped-Express-UTM-Builder-Logo-share-1024x536-1-e1574432386315.png'
  return <img alt='logo' src={src} height='50' {...props} />
}

const BROWSER = getBrowser();
const EXT_LINKS = {
  Chrome: 'https://chrome.google.com/webstore/detail/utm-builder/flockfgmfgmpmimlfkkeepkblckodbjg',
  Firefox: 'https://addons.mozilla.org/en-US/firefox/addon/utm-builder/',
  Other: 'https://chrome.google.com/webstore/detail/utm-builder/flockfgmfgmpmimlfkkeepkblckodbjg'
}
function openExtension(e) {
  /// should maybe open popup first
  window.open(EXT_LINKS[BROWSER])
}
function ExtensionButton(props) {
  return <Btn className="extensionButton" onClick={openExtension}>
    <span className="fas fa-download left" />
    Get the extension
  </Btn>
}
function BookCallButton(props) {
  return <Link to='/book-call'>
    <Btn className="extensionButton" style={{ marginRight: '15px' }}>
      <span className="fas fa-phone fa-flip-horizontal left" />
      Book a Call
    </Btn>
  </Link>
}

function checkIsAdmin(history) {
  return /^\/admin/.test(history.location.pathname)
}

function checkExclusion(exclude = [], location, match) {
  const pathname = location.pathname
  return exclude.concat([
    /frames/
  ]).some(reg => {
    if (typeof reg === 'string') {
      return reg === pathname || reg === match.path
    }
    return reg.test(pathname)
  })
}


export default function NavBar(props) {
  const location = useLocation()
  const match = useRouteMatch()
  const user = useAppSelector(state => state.userState.user)
  if (checkExclusion(props.exclude, location, match)) {
    return null;
  }
  if (user) {
    return <LoggedInNav {...props} />
  }
  return <LoggedOutNav />
}

function LoggedInNav() {
  const history = useHistory()
  const goBack = (e) => {
    e.preventDefault()
    history.goBack()
  }
  const isAdmin = checkIsAdmin(history)
  const style = NAV_IN_S
  return <>
    <div style={style} />
    <nav className="navbar navbar-default light" style={style}>
      <div className='pull-left' style={{ display: "flex", gap: "5px", padding: '10px 40px', fontSize: "16px", marginTop: "20px" }}>
        {isAdmin && <Link to='/admin'>Admin Home</Link>}
        <Link to='..' onClick={goBack}>
          <Fa i='arrow-left' /> Back
        </Link>
        <Link to='/app/dash'>
          Dash
        </Link>
      </div>
      <div className="pull-right inline-flex">
        <BookCallButton />
        <ExtensionButton />
        <AccountTool />
      </div>
    </nav>
  </>
}

function LoggedOutNav() {
  const style = NAV_OUT_S
  return <React.Fragment>
    <div style={style} />
    <nav className="navbar navbar-default light" style={style}>
      <div className='pull-left inline-flex' style={{ padding: '10px 40px' }}>
        <Link to='/login'>
          <Logo />
        </Link>

      </div>
      <div className="pull-right inline-flex">
        <BookCallButton />
        <ExtensionButton />
        <Link to='/login'>
          <Btn className='extensionButton' style={{ color: 'black', width: 'auto' }}>
            Login <Fa i='user' />
          </Btn>
        </Link>
      </div>
    </nav>
  </React.Fragment>;
}

function getBrowser() {
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  if (isChrome) {
    return 'Chrome';
  }
  var isFirefox = typeof InstallTrigger !== 'undefined';
  if (isFirefox) {
    return 'Firefox'
  }
  return 'Other'
}






function methodsInit(){
	return {
		addPermission:{error:null,loading:false},
		removeUser:{error:null,loading:false},
		changeOwner:{error:null,loading:false}
	}
}
function initState(){
	return {
		profileId:null,
		fixedProfile:false,
		activeTab:'ADD',
		email:'',
		tags:'',
		permission:'read',
		linkViewAccess:'all',
		accessRole:'read::all',
		editUser:null,
		...methodsInit()
	}
}
function reInit(state={}){
	let data = initState()
	//eslint-disable-next-line
	for(let k in state){
		data[k] = state[k] === undefined ? data[k] : state[k]
	}
	return data
}
function setVar(key,state,data){
	return {...state,[key]:data}
}
function setTab(state,data){
	return {...state, activeTab:data, editUser:null,addPermission:{error:null,loading:false}}
}
function addPermission(state,data){
	let {addPermission} = state;
	state = {...state,addPermission:{...addPermission,...data}}
	if(data.data && state.email === data.requestPayload.email){
		return {...state, email:'' }
	}
	return state
}
function changeOwner(state,data){
	let {changeOwner,editUser} = state;
	if(data.data){
		data.data.permissions.forEach(function(user){
			if(editUser && editUser._id === user._id){
				editUser = {...editUser,...user}
			}
		})
	}
	return {...state, changeOwner:{...changeOwner,...data},editUser }
}
function removeUser(state,data){
	let {removeUser} = state;
	return {...state, removeUser:{...removeUser,...data} }
}
function setEditUser(state,data){
	if(!data){
		return {...state, editUser:null, ...methodsInit() }
	}
	let editUser = state.editUser || {}
	editUser = { ...state.editUser, ...data};
	let {permission='read',linkViewAccess='all'} = editUser;
	editUser.accessRole = permission+'::'+linkViewAccess
	return { ...state, editUser}
}
function setAccess(state,data){
	let [permission,linkViewAccess] = data.split('::')
	let {editUser} = state;
	if(editUser){
		editUser = {
			...editUser,
			linkViewAccess,
			permission,
			accessRole:data
		}
	}
	state = {
		...state,
		editUser,
		linkViewAccess,
		permission,
		accessRole:data
	}
	return state;
}
function profileSharing(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'PS_INIT':
			return reInit( data || {} )
		case 'PS_CLOSE':
			return setTab(state,'CLOSING') // special case used to trigger a close
		case 'PS_SET_TAB':
			return setTab(state,data)
		case 'PS_SET_PROFILE':
			return setVar('profileId',state,data)
		case 'PS_SET_EMAIL':
			return setVar('email',state,data)
		case 'PS_SET_TAGS':
			return setVar('tags',state,data)
		case 'PS_SET_PERMISSION':
			return setVar('permission',state,data)
		case 'PS_SET_EDIT_USER':
			return setEditUser(state,data)
		case 'PS_SET_ACCESS':
			return setAccess(state,data)
		case 'CHANGE_OWNER':
			return changeOwner(state,data)
		case 'ADD_USER_PERMISSION':
			return addPermission(state,data)
		case 'REMOVE_USER_PERMISSION':
			return removeUser(state,data)
		default:
			return state
	}
}	

export default profileSharing


import React from 'react';
import ReactDOM from 'react-dom/client';
import Sentry, {init} from './sentry'
import * as serviceWorker from './serviceWorker'
import api from './utils/api.ts';
import * as Redux from 'react-redux'
import App from './App'
import './index.css';
// this creates a new function - NB only available in async loaded modules
Redux.connect.comp = function(Comp,options){
	let {mapState, mapDispatch} = Comp
	return Redux.connect(mapState, mapDispatch,options)(Comp)
}
window.env = process.env.NODE_ENV 

if(process.env.NODE_ENV === 'production'){
	init()
	let APP_VERSION = null;
	async function monitorVersion(){
		const res = await api.req({ type:'metainfo', action:'version' })
		if(!res.data){
			console.log(res);
			Sentry.captureException(new Error(res.error))
			return
		}
		if(!APP_VERSION){
			APP_VERSION = res.data.version;
		}
		if(APP_VERSION !== res.data.version){
			window.location.reload(true)
		}
	}
	setInterval(monitorVersion, 1000*60*5)
}else{
	// utility functions for dev env
	window.logout = function(){
		delete localStorage.userData;
		window.location.reload()
	}
}

window.Sentry = Sentry
const isProd = process.env.NODE_ENV === 'production'
window.__keys = {
	"STRIPE_PUBLIC":!isProd ? "pk_test_tvzSlfCT15pesYml6oiViSgN" : 'pk_live_GunkLBFE3uckYIcJ19z98vdU'
};

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App/>)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import Immutable from 'immutable'
export const BASIC_BUILDER_KEY = 'basicBuilderState';

const HALF_WAY_STEPS = 3

export function calculateProgress(state){
	let {selectedDatapoints,currentStage,type} = state;
	const isTutorial = type === 'tutorial' ? 1 : 0
	const addSteps = selectedDatapoints.filter(id=>id !== 'date').length
	const totalSteps = 3 + addSteps + 1 + isTutorial;
	const halfWaySteps = HALF_WAY_STEPS + isTutorial
	let startI = currentStage > halfWaySteps ? 2 : currentStage;
	let endI = currentStage > halfWaySteps ? currentStage - halfWaySteps + 1 : 0;
	const rStart = (startI + 1) / halfWaySteps
	const rEnd = (endI)/(totalSteps - halfWaySteps)
	const newData = {
		completionPercentage:Math.round(( rStart + rEnd )*100/2)/100,
		totalSteps,
		currentStage
	}
	if(newData.completionPercentage !== state.completionPercentage || totalSteps !== state.totalSteps){
		let track = window.trackEvent || function(){}
		track('basicBuilder-funnel','progress',currentStage,newData.completionPercentage, totalSteps)
		return {...state,...newData}
	}
	return state
}
function defState(){
	const state = {
		type:'tutorial',
		profile: null,
		profileName: '',
		fields: {},
		utms:{
			utm_source:['source'],
			utm_medium:['medium'],
			utm_campaign:[],
			utm_term: [],
			utm_content: []
		},
		completionPercentage:0,
		currentStage:0,
		selectedDatapoints:[],//ids of selected datapoints
		pendingSelectedDatapoint:null,
		hintModal: false,
		createProfile:{loading:false,error:null}
	}
	return calculateProgress(state)
}
function getCache(){
	let cache = localStorage.getItem(BASIC_BUILDER_KEY)
	try{
		let value = JSON.parse(cache) || null
		if(!value){
			return null
		}
		if(value.profile){
			value.profile = Immutable.fromJS(value.profile);
		}
		value.pendingSelectedDatapoint = null // reset this warning each reload
		return calculateProgress(value)
	}catch(error){
		return null
	}
}
function setCache(data){
	if(!data){
		return localStorage.removeItem(BASIC_BUILDER_KEY);
	}
	return localStorage.setItem(BASIC_BUILDER_KEY,JSON.stringify(data))
}
function initState(){
	let state = defState()
	let data = getCache() || {};
	return {...state,...data};
}
const OBLIGATORY_STEPS = [
	//'Profile Name',
	'Source','Medium','Datapoint Selection'
];
const REQUIRED_STEPS_LENGTH = OBLIGATORY_STEPS.length;// currentStage is 0 index
function setStep(state,currentStage) {
	let {selectedDatapoints,type} = state;
	const isTutorial = type === 'tutorial' ? 1 : 0
	const includesDate = selectedDatapoints.includes('date') ? 1 : 0
	const datapointsToDo = selectedDatapoints.length - includesDate
	if(currentStage - datapointsToDo === (REQUIRED_STEPS_LENGTH + isTutorial)) {
		return { 
			...state, currentStage, 
			profile:Immutable.fromJS( makeProfile(state) ),
			createProfile:{loading:false,error:null} 
			// if we recreate the profile we should clear the saving details
			// perhaps this should even be done earlier
		}
	}
	return calculateProgress({...state, currentStage})
}
function nextStep(state){
	let {currentStage} = state
	return setStep(state,currentStage + 1)
}
function lastStep(state){
	let step = state.currentStage - 1;
	if(step < 0){
		step = 0
	}
	return setStep(state,step)
}
function toggleDatapoint(state, id){
	let { pendingSelectedDatapoint, selectedDatapoints, utms } = state;
	let { utm_campaign } = utms;
	const isSelected = selectedDatapoints.includes(id);
	const shouldWarn = selectedDatapoints.length === 4; 
	// show a warking if they have 4 already
	// this warning should only be shown one time, regardless or rejection or acceptance

	// if its not currently selected and the count is 4 and we havent asked before
	// works cause of type overload, loose equal with undefined catches accidental deletation of field
	// eslint-disable-next-line 
	if(!isSelected && shouldWarn && pendingSelectedDatapoint == undefined){ 

		return {...state, pendingSelectedDatapoint:id} //later we will use this
	}

	if(utm_campaign.includes(id)) {
		utm_campaign = utm_campaign.filter(ele=>ele !== id);
	}else{
		utm_campaign = utm_campaign.concat([id])
		if(id === 'date'){/// special case
			let {fields} = state;
			fields = { ...fields, date:['0::Today','1::Tomorrow','2::+2','3::+3'].join()}
			state = { ...state, fields };
		}
	}
	utms = {...utms, utm_campaign};
	if(isSelected) {
		selectedDatapoints = selectedDatapoints.filter(ele=>ele !== id);
	} else {
		selectedDatapoints = selectedDatapoints.concat([id]);
	}
	return calculateProgress({...state, utms, selectedDatapoints})
}
function confirmPendingDatapoint(state,bool){
	const {pendingSelectedDatapoint} = state;
	if(!pendingSelectedDatapoint){
		throw new Error('Must be a pendingSelectedDatapoint to BASIC_BUILDER_CONFIRM_PENDING_DATAPOINT')
	};
	state = {...state,pendingSelectedDatapoint:false};
	if(bool){
		return toggleDatapoint(state,pendingSelectedDatapoint)
	}
	return state;
}
function cleanValue(inputValue){
	let [value,text] = inputValue.split('::').map(v=>v.trim());
	text = text || value;
	value = value.trim().toLowerCase().replace(/[^a-z0-9]+/g,'-')
	return [value,text]
}
function setField(state,payload) {
	let { fields } = state
	let { id, value:inputValue } = payload;
	let [value,text] = cleanValue(inputValue)
	if(!value){
		return state;
	}

	let field = (fields[id] || '').split(',').filter(v=>v);
	// field.includes(value) old way which is cleaner but only works with less logic
	const includedField = field.find(v=>{
		return v.split('::')[0] === value;
	})
	if(includedField) {
		field = field.filter(function(v){
			return v.split('::')[0] !== value;
		});
	} else {
		let toAdd = value === text ? value : value+'::'+text
		field = field.concat([toAdd])
	}
	fields = { ...fields, [id]:field.join() }
	return { ...state, fields };
}
function setState(state,data){
	return {...state, ...data}
}
function makeDateField(id){
	const list = ['0::Today','1::Tomorrow','2::+2','3::+3']
	return {
		title:id,
		id,
		type:'buttons',
		defaultHidden:false,
		otherType:'calandar',
		hasOther:true,
		fields:list.map(inputValue=>{
			let [value,text] = inputValue.split('::');
			return { value,text }
		})
	}
}
function makeProfile(props){
	let { utms,fields:fieldData,profileName} = props
	utms = Object.keys(utms).map(k=>({id:k,list:utms[k]}))
	// get the utms list
	let utmList = utms.reduce((tot,utm)=>utm.list.concat(tot),[]) // make a full utm list
	let fieldsList = Object.keys(fieldData).filter(f=>utmList.includes(f))
	// get the fields that are in someway used in the utms

	let datapoints = fieldsList.map(function(id,i){
		let value = '${'+id+'}'
		return {
			id, col_id:i,
			reciepes:[
				{
					value:id === 'date' ? `DATE(${value})` : value,
					tests: []
				}
			]
		}
	})
	const fields = fieldsList.map(function(id){
		let list = fieldData[id].split(',')
		if(id === 'date'){
			return makeDateField(id,list)
		};
		const hasOther = list.includes('other')
		return {
			title:id,
			id,
			type:'buttons',
			defaultHidden:false,
			otherType:hasOther ? 'text' : 'none',
			hasOther,
			fields:list.filter(v=>v!=='other').map(inputValue=>{
				let [value,text] = inputValue.split('::');
				text = text || value;
				return { value,text }
			}).filter(v=>v.value)
		}
	})
	const config = {
		fields,
		utms,
		datapoints,
		actions: [],
		tests: []
	}
	return {
		config,
		_id:'quick-start',
		title:profileName,
		description:'Add a description...',
		temp_id:'quick-start',
		profileType:'fromTemplate',
		active:true,
		configMode:'normal',
		linkSettings:{
			includeUUID: false,
			appendMethod:'append',
			utmName:'_li',
			convention:'ga' ,
			dataPointSeperator:'_'
		},
	}
}
function clearState(){
	setCache(null);
	return initState();
}
function createProfile(state,payload){
	if(payload.data){ // if creating a profile works clear the localCache
		state = clearState();
	}
	return {
		...state, 
		createProfile:payload
	}
}
function toggleHint(state,data){
	let {hintModal} = state;
	hintModal = data === undefined ? !hintModal : !!data;
	return {...state, hintModal};
}
function moveDatapoint(state,{origin,dest}){
	/* 
	data = {
		origin:{row,col}, dest:{row,col}
	}
	*/
	let {profile} = state;
    let utms = profile.getIn(['config','utms']);
    let item = utms.getIn([origin.row, 'list', origin.col]);
    utms = utms.deleteIn([origin.row, 'list', origin.col]);
    let list = utms.getIn([dest.row, 'list']);
    utms = utms.setIn([dest.row, 'list'], list.insert(dest.col, item));
    profile = profile.setIn(['config','utms'],utms)
    return {...state, profile};
}
function setProfileTitle(state, title){
	console.log(title, state)
	let {profile} = state;
	if(profile){
		profile = profile.set('title',title)
	}
	return {...state, profile, profileName:title}
}
function setup(state,data){
	return {...state, ...data}
}
function basicBuilder(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'BASIC_BUILDER_INIT':
			return setup(state,data)
		case 'BASIC_BUILDER_NEXTSTEP':
			return nextStep(state,data)
		case 'BASIC_BUILDER_PREVSTEP':
			return lastStep(state,data)
		case 'BASIC_BUILDER_SETSTEP':
			return setStep(state,data)
		case 'BASIC_BUILDER_SETFIELD':
			return setField(state,data)
		case 'BASIC_BUILDER_TOGGLE_DATAPOINT':
			return toggleDatapoint(state,data)
		case 'BASIC_BUILDER_CONFIRM_PENDING_DATAPOINT':
			return confirmPendingDatapoint(state,data);
		case 'BASIC_BUILDER_SETSTATE':
			return setState(state,data)
		case 'CREATE_PROFILE': // shared with profiles
			return createProfile(state,data);
		case 'BASIC_BUILDER_CLEAR_STATE':
			return clearState();
		case 'BASIC_BUILDER_TOGGLE_HINT':
			return toggleHint(state,data)
		case 'BASIC_BUILDER_MOVE_DATAPOINT':
			return moveDatapoint(state,data)
		case 'SET_PROFILE_TITLE':
			return setProfileTitle(state,data)
		default:
			return state
	}
}	

export default function basicBuilderWrapper(state, action){
	let newState = calculateProgress(basicBuilder(state,action))
	if(newState !== state){
		setCache(newState);
	}
	return newState
}


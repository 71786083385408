import { v4 as uuidv4 } from 'uuid'
import { Profile } from '@utm-builder/ui/dist/types';
import _ from 'lodash';


export const uuid = function(){
    return uuidv4().replace(/-/g,'')
}

export const DEFAULTS = {
    LINKSETTINGS(): Profile.Config.LinkSettings {
        return {
            convention: 'ga',
            'utmName': '_li',
            'appendMethod': 'append',
            'includeUUID': false,
            'dataPointSeperator': '_',
            prefixDatapoints: undefined,
        }
    },
    FIELD:(): Profile.Config.Field=>{
        return {
            "title" : "New Field",
            "id" : "newField",
            "type" : "buttons",
            "fields" : [],
            "otherType" : "none",
            strictSuggestions:false,
            ignoreValidation: false,
            //@ts-ignore
            suggestionType: 'none',
            suggestions:[],
            defaultHidden: false
        }
    },
    DATAPOINT:(): Profile.Config.Datapoint=>{
        return {
            id:'tempVar',
            //@ts-ignore
            col_id: null,
            reciepes:[
                {
                    value:'na',
                    tests:[]
                }
            ]
        }
    },
    PREP_FUNNEL:(): Profile.Config.Ifs =>{
        return {
            id:Date.now().toString(),
            //@ts-ignore
            tempVar:'tempVar',
            tests: [],
            value: 'na'
        }
    },
    FIELD_ELEMENT:(): Profile.Config.FieldButton =>{
        return {
            value: 'new-field',
            text: 'New Field'
        }
    },
    TEST: (): Profile.Config.Test =>{
        return {
            id:'test_'+uuid(),
            time:'redraw',
            action:'unknown',
            tests:[]
        }
    },
    ACTION:(): Profile.Config.Action =>{
        return {
            id:'action_'+uuid(),
            action: 'hideFields',
            actionables: []
        }
    },
    UTM:(): Profile.Config.UTM =>{
        return {
            id: 'custom_utm',
            list: []
        }
    }
} as const;

const COLUMNS: ("none" | `col_${number}`)[] = [
    'none',
];
for(var i = 1;i<21;i++){
    COLUMNS.push('col_' + i as `col_${number}`);
};
export function getUnusedCols(datapoints: Profile.Config.Datapoint[]){
    const usedCols = datapoints.map(function(f){
        return f.col_id?.startsWith('col_') ? f.col_id : 'never'
    });
    return COLUMNS.filter(function(c){
        return !usedCols.includes(c)
    })
}

function listHasValue<T extends Record<string, any>>(value: string, list: T[], id: keyof T){
    return list.some(function(el){
        return el[id] === value
    })
};

export function listHasId<T extends Record<string, any>>(value: string, list:T[]){
    return listHasValue(value, list, 'id');
}

export function basicDataPoint(newId?: string): Profile.Config.Datapoint{
    const datapoint = getDefault('DATAPOINT')
    const id = newId || datapoint.id
    datapoint.id = id
    datapoint.reciepes[0].value = '${'+id+'}'
    return {
        ...datapoint,
        id,
    }
}

export function getDefault<T extends keyof typeof DEFAULTS>(id: T): ReturnType<typeof DEFAULTS[T]>{
    const fn: typeof DEFAULTS[T] = DEFAULTS[id]
    return _.cloneDeep(fn()) as unknown as ReturnType<typeof DEFAULTS[T]>
}

export function reorder<T>(list: T[], i: number, direction: -1 | 1): T[]{
    let newList = list.concat([])
    if( (i === 0 && direction < 0) || (i === newList.length && direction > 0) ){
        return newList
    }
    const el = newList.splice(i, 1)[0]
    newList.splice(
        i + direction,
        0,
        el
    )
    return newList
}

export function moveItem<T>(list: T[], origin: number, dest: number): T[]{
    let item = list[origin]
    let outputList: (T | '__REMOVE')[] = list.concat([])
    outputList[origin] = '__REMOVE'
    const insertAt = dest > origin ? dest + 1 : dest
    outputList.splice(insertAt, 0, item)
    return outputList.filter(v=>v!=='__REMOVE') as T[]
}
function initState(){
	const state = {
		selectedProfile:null,
		showProfileListModal:false,
		profileListModalFilter:'',
		shareProfileId:null
	}
	return state;
}
function setShare(state,shareProfileId){
	return {...state,shareProfileId}
}

function setProfile(state, profileId){
	return { 
		...state,
		selectedProfile: profileId,
		showProfileListModal: false // 
	}
}

function toggleProfileList(state) {
	const {showProfileListModal} = state;
	return { 
		...state,
		showProfileListModal:!showProfileListModal,
		profileListModalFilter:'' // if showing or removing then reset the filter
	}
}
function setProfileListFilter(state,value){
	return {...state,profileListModalFilter:value}
}
function getProfiles(state,{error,loading,data}){
	if(state.selectedProfile){
		return state
	}
	if(!data || !data.length){
		return state
	}
	return { ...state, selectedProfile:data[0]._id}
}

function dashboardView(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'GET_PROFILES':
			return getProfiles(state,data)
		case 'DASH_SET_PROFILE':
			console.log(data)
			return {
				...state,
				selectedProfile:data
			}
				 // setProfile(state,data)
		case 'DASH_TOGGLE_PROFILE_LIST':
			return toggleProfileList(state,data)
		case 'DASH_SET_PROFILE_LIST_FILTER':
			return setProfileListFilter(state,data)
		case 'DASH_SET_SHARE_PROFILE':
			return setShare(state,data);
		default:
			return state
	}
}	

export default dashboardView


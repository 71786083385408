import { REGS } from '../constants/index.js';
const SEND_RESET = 'SEND_RESET'
const ERRORS = {
	PASSWORD:{
		SHORT:"Password Must be at least 9 characters long.",
		NEEDS_NUM:"Password must contain at least one Number",
		NEEDS_CAP:"Password must contain at least one Capital Letter",
		MATCH:"Passwords don't match."
	}
}
function getPassErrors(password){
	let errors = []
	if(password.length < 9){
		errors.push(ERRORS.PASSWORD.SHORT)
	}
	if(password.search(/\d/) === -1 ){	
		errors.push(ERRORS.PASSWORD.NEEDS_NUM)
	}
	if(password.search(/[A-Z]/) === -1 ){
		errors.push(ERRORS.PASSWORD.NEEDS_CAP)
	} 
	return errors
}
function getPassError(passwords){
	const [
		pass,repeatPassword
	] = passwords;
	let errors = [
		{hasError:false,errors:[]},
		{hasError:false,errors:[]}
	];
	let passErrors = getPassErrors(pass)
	errors[0].errors = passErrors
	errors[0].hasError = !!passErrors.length;

	if(pass.length < 10) {
		// not critical
		errors[0].errors.push("Password should have more than 10 characters")
	}

	if(pass !== repeatPassword){
		errors[1].errors.push(ERRORS.PASSWORD.MATCH)
		errors[1].hasError = true;
	}
	return errors;
}
function validateForm(state){
	let i = 0
	let errors = {
		name:!state.name.length && 'A Name is required',
		email:!REGS('EMAIL').test(state.email) && 'Valid Email Required',
		agreed:!state.agreed && 'Must Agree to T&Cs',
		passwords:getPassError(state.passwords)
	};
	for(var k in errors){
		let err = errors[k];
		if(k === 'passwords'){
			if(err[0].hasError || err[1].hasError){
				i++
			}
		}else if(err){
			i++
		}
	}
	errors.count = i
	return errors
}


function initState(){
	const state = {
		passwords:['',''],
		email:'',
		name:'',
		promoCode:'',
		agreed:false,
		authAction:'login',
		sendPasswordReset:{
			loading:false,
			error:null,
			data:null
		}
	}
	state.formErrors = validateForm(state)
	return state;
}

function setLoginDetails(state,data){
	state = { ...state, ...data }
	state.formErrors = validateForm(state);
	return state
}

function sendPasswordReset(state,data){
	let sendPasswordReset = {...state.sendPasswordReset, ...data}
	return { ...state, sendPasswordReset }
}

function auth(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case SEND_RESET:
			return sendPasswordReset(state,data)
		case 'setLoginDetails':
			return setLoginDetails(state,data)
		case 'LOGOUT': // resets the auth params if you logogut
			return initState()
		default:
			return state
	}
}	

export default auth











function initState(){
	const state = {
		getTemplates:{loading:false,error:null,complete:false}
	}
	return state;
}
function updateProfile(state,payload){
	let { templateId:id } = payload
	let profile = state[id] || {};
	profile = {...profile,...payload}
	return Object.assign({},state,{[id]:profile})
}
function getProfiles(state,{error,loading,data}){
	let dupState = Object.assign({},state);
	let reqState = {error,loading,complete:state.getTemplates.complete || !!data}
	if(data){
		data.forEach(function(profile){
			let _id = profile._id;
			dupState[_id] = dupState[_id] || {data:true,error:null}
			dupState[_id].data = profile
		})
	}
	return {...dupState,getProfiles:{...state.getProfiles,...reqState}}
}
function templates(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'GET_TEMPLATES':
			return getProfiles(state,data)
		case 'GET_TEMPLATE':
			return updateProfile(state,data)
		default:
			return state
	}
}	

export default templates


import api from './api.ts';
export async function paySubscription(stripeApi, payload){
	const { subscriptionId, source,} = payload
	const res = await api.subscriptions(
		'updateStripe',
		{
			subscriptionId,
			update:{
				collection_method: 'charge_automatically',
				default_source: source.id,
			}
		}
	)
	if(res.error){
		return res
	}
	const { stripe } = res.data;
	const { latest_invoice } = stripe;
	if(!latest_invoice.payment_intent){
		return {data:res.data,error:null}
	}
	const {
		status:intentStatus,
		client_secret:intentSecret,
	} = latest_invoice.payment_intent
	switch(intentStatus){
		case 'requires_payment_method':{
			return {error:'PAYMENT_METHOD_DECLINED'}
		}
		case 'requires_action':{
			let scaRes = await stripeApi.handleCardPayment(intentSecret)
			if(scaRes.error){
				return scaRes;
			}
			let sub = res.data;
			sub.isActive = true;
			// ping off the the server asking it to check the stripe status
			api.subscriptions('syncStripe',{subscriptionId})
			return {data:sub, error:null}
		};
		case 'succeeded':
		default:
	}
	return {data:res.data,error:null}
}










function initState(){
	const state = {
		getProfiles:{loading:false,error:null,complete:false},
		changeOwner:{loading:false,error:null,complete:false},
		removeUserPermission:{loading:false,error:null,complete:false},
		addUserPermission:{loading:false,error:null,complete:false},
	}
	return state;
}

function updateProfile(state, payload){
	let { profileId } = payload
	let profile = state[profileId] || {data:null,error:null,isProfile:true};
	profile = { ...profile, ...payload }
	return { ...state, [profileId]:profile }
}
function createProfile(state, payload) {
	if(!payload.data){
		return state;
	}
	payload.profileId = payload.data._id
	return updateProfile(state, payload)
}

function getProfiles(state,{error,loading,data}){
	const reqState = {error,loading,complete:state.getProfiles.complete || !!data}
	const dupState = {
		...state,
		getProfiles:{...state.getProfiles,...reqState}
	};
	if(data){
		data.forEach(function(profile){
			const _id = profile._id;
			//profile.active = new Date(profile.expiresAt) < now && profile.active
			dupState[_id] = dupState[_id] || {data:null,error:null,isProfile:true}
			dupState[_id] = {
				...dupState[_id],
				data:profile
			}
		})
	}
	return dupState
}

function deleteProfile(state,{error,loading,data,profileId}){
	const dupState = { ...state }
	delete dupState[profileId];
	return dupState
}

function addUserPermission(state, params){
	let { profileId, data } = params;
	let profile = state[profileId]
	if(data){
	    profile = {
			...profile,
			data:{
				...profile.data,
				permissions:data.permissions 
			} 
		}
	}
    return {...state, [profileId]:profile,addUserPermission:params}
}
function removeUserPermission(state, params){
	let {requestPayload,profileId,data} = params
	let profile = state[profileId]
	if(data){
		let permissions = profile.data.permissions
	    	.filter(per=>per.email!==requestPayload.email)
		profile = {
			...profile,
			data:{
				...profile.data,
				permissions
			} 
		}
	}

    return {
		...state,
		[profileId]:profile,
		removeUserPermission:params
	}
}
function changeOwner(state,params){
	let {requestPayload,profileId,data} = params
	let profile = state[profileId]
    if(data){
    	let { permissions } =  profile.data
		permissions = permissions.map(function(el){
			if(requestPayload.newOwnerId === el._id){
				return {...el,permission:'owner'}
			}else if(el.permission === 'owner'){
				return {...el,permission:'editor'}
			}
			return el;
		})
	    let data = {...profile.data, permissions }
	    profile = {...profile,data};
	}
    return {...state, [profileId]:profile,changeOwner:params}
}
function toggleActiveStatus(state,payload){
	let {active,profileId} = payload;
	let profile = state[profileId]
	if(!profile){
		return state
	}
	active = active === undefined ? !profile.data.active : active;
	return { 
		...state,
		[profileId]:{
			...profile,
			data:{
				...profile.data,
				active,
				updateId:Date.now()
			}
		}
	}
}
function mergeProfileElement(state, payload){
	const profileId = payload._id;
	const profile = state[profileId]
	if(!profile){
		return state
	}

	return {
		...state,
		[profileId]: {
			...profile,
			data: {
				...profile.data,
				...payload
			}
		}
	}
}
function getFieldHistory(state,payload){
	let {profileId,fieldId,data} = payload;
	let profile = state[profileId]
	if(!profile || !data){
		return state
	}
	if(typeof data === 'string'){
		data = [data]
	}
	const fields = profile.data.config.fields.map(function(f){
		if(f.id !== fieldId){
			return f
		}
		const extractHist = data.map(el=>el.value || el).concat(f.historySuggestions||[])
		return { 
			...f,
			historySuggestions: [...new Set(extractHist)]
		}
	});
	return {
		...state,
		[profileId]:{
			...profile,
			data:{
				...profile.data,
				config:{
					...profile.data.config,
					fields
				},
				updateId:Date.now() // need it to alter render
			}
		}
	}
}
function setFieldHistories(state,payload){
	let {profileId,fieldValues} = payload;
	let profile = state[profileId]
	if(!profile){
		return state
	}
	//eslint-disable-next-line
	for(let field of fieldValues){
		state = getFieldHistory(state,{
			data:[field.value],
			fieldId:field.title,
			profileId
		})
	}
	return state;
}
function profiles(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'LOGOUT':
			return initState()
		case 'DELETE_PROFILE':
			return deleteProfile(state,data)
		case 'GET_PROFILES':
			return getProfiles(state,data)
		case 'DES_LOAD_PROFILE':
		case 'GET_PROFILE':
		case 'UPDATE_PROFILE':
			return updateProfile(state,data)
		case 'CREATE_PROFILE':
			return createProfile(state,data)
		///////////////
		case 'ADD_USER_PERMISSION':
			return addUserPermission(state,data);
		case 'REMOVE_USER_PERMISSION':
			return removeUserPermission(state,data);
		case 'CHANGE_OWNER':
			return changeOwner(state,data);
		case 'TOGGLE_ACTIVE_STATUS':
			return toggleActiveStatus(state,data)
		case 'MERGE_PROFILE_VALUES':
            return mergeProfileElement(state,data);
        case 'GET_FIELD_HISTORY':
        	return getFieldHistory(state,data)
        case 'SET_FIELD_HISTORIES':
        	return setFieldHistories(state,data)
		default:
			return state
	}
}	

export default profiles


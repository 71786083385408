import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { track } from '../utils/api.ts'
// import { ReactComponent as Logo } from '../images/logoWhiteText.svg';
import '../css/dashboard.css';

// https://bootsnipp.com/snippets/dlPaP

const SideBarLink = withRouter(function SideBarLink(props) {
  const { location, hash, title, children } = props;
  let isActive = location.hash === hash || location.pathname === props.to;
  let to = hash ? location.pathname + '#' + hash : props.to;
  const isHREF = /^http/.test(to);
  const Comp = isHREF ? 'a' : Link
  return <Comp to={isHREF ? undefined : to} href={isHREF ? to : undefined} >
    <li className={!isActive ? "c-menu__item" : 'c-menu__item is-active'} title={title}>
      <div style={{ minHeight: !props.sm ? '60px' : '40px' }} className="c-menu__item__inner">
        <div className="c-menu-item__title"><span>{title}</span></div>
      </div>
      {children}
    </li>
  </Comp>
})

function DropDownNav(props) {
  const [showMenu, setShowMenu] = useState(false)
  function toggleShowMenu() {
    const newVal = !showMenu
    track('dash-sidebar', 'toggle-help', null, newVal)
    setShowMenu(newVal)
  }
  return <React.Fragment>
    <li style={{ cursor: 'pointer' }} onClick={toggleShowMenu} className="c-menu__item" title="Help">
      <div className="c-menu__item__inner dropdown">
        <div className="c-menu-item__title dropdown">
          <span>Help</span>
          <span style={{ fontWeight: '900', float: 'right', marginRight: '20px' }}
            className="fas fa-caret-down" />
        </div>
      </div>
    </li>
    {showMenu && <div style={{ marginLeft: '20px' }}>
      <SideBarLink sm to='https://utm-builder.com/faq' title='FAQs' />
      <SideBarLink sm to='https://utm-builder.com/documentation' title='Documentation' />
      <SideBarLink sm to='/support-form' title='Contact Us' />
      <SideBarLink sm to='/book-call' title='Book a Call' />
    </div>}
  </React.Fragment>
}

// const LOGO_STLYE = {
//   width: '160px', 
//   marginBottom: '60px',
//   textAlign:'center',
//   marginLeft:'auto', 
//   marginRight:'auto', 
//   display:'block'
// }
function SideBar(props) {
  return <div className="l-sidebar">
    {/*<Logo title='UTM-Builder Logo' width='100' height='100' style = {LOGO_STLYE}/>*/}
    <img width='210' alt='logo' style={{ margin: '40px auto 40px', display: 'block' }} src='https://utm-builder.com/wp-content/uploads/2019/11/logo-mit-text-utm-builder-scaled.png' />
    <div className="l-sidebar__content">
      <nav className="c-menu js-menu">
        <ul className="u-list">
          <SideBarLink to='/app/dash' title='Dashboard' />
          <SideBarLink to='/app/user-management' title='User management' />
          <SideBarLink to='/app/shortener-domains' title='Shorteners' />
          <SideBarLink to='/app/invoices' s title='Invoices' />
          <DropDownNav />
        </ul>
      </nav>
    </div>
  </div>
}

const DASHAREA_STYLE = {
  paddingTop: '20px'
}
export default function DashArea(props) {
  return <div className='sidebar-is-expanded'>
    <SideBar />
    <main className="l-main" style={DASHAREA_STYLE}>
      {props.children}
    </main>
  </div>
}


import { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import api, { track } from "../utils/api";
import { useAppDispatch } from "../redux/hooks";

class HubspotChat {
  HubSpotConversations?: any;
  onReady: (() => void)[];
  isReady: boolean;
  constructor() {
    this.isReady = false;
    //@ts-ignore
    this.HubSpotConversations = window.HubSpotConversations;
    //@ts-ignore
    const onReady = window.hsConversationsOnReady;
    //@ts-ignore
    window.hsConversationsOnReady = onReady || [];
    this.onReady = onReady || [];
    this.onReady.push(() => {
      //@ts-ignore
      this.HubSpotConversations = window.HubSpotConversations;
      this.isReady = true;
    });
  }
  isLoaded(): boolean {
    //@ts-ignore
    return window?.HubSpotConversations?.widget?.status()?.loaded || false;
  }
  _command(method: string, params?: any) {
    if (!this.isReady) {
      this.onReady.push(() => {
        this._command(method, params);
      });
      return;
    }
    this.HubSpotConversations?.widget?.[method]?.(params);
  }
  changePath(pathname: string) {
    const desiredStatus = /(frame|templates)\/|book-call/.test(pathname);
    if (desiredStatus) {
      return this.remove();
    }
    return this.load();
  }
  load() {
    //this._command("load");
    return undefined;
  }
  remove() {
    this._command("remove");
  }
}
const hsChat = new HubspotChat();
//@ts-ignore
window.hsChart = hsChat;
export function PagePathListener() {
  const location = useLocation();
  useEffect(
    function() {
      hsChat.changePath(location.pathname);
      track("page-view", "page-view");
    },
    [location.pathname]
  );
  return null;
}

type RouteAndHref = Route["props"] & { href: string };
export function ExtenternalRedirect({ href, ...restProps }: RouteAndHref) {
  return (
    <Route
      {...restProps}
      component={() => {
        setTimeout(() => {
          // give time to ping stats
          window.location.href = href;
        }, 100);
        return null;
      }}
    />
  );
}

export function FullIframe(props: { href: string, title?: string }) {
  const { href } = props
  const title = props.title || href;
  return <iframe
    title={title}
    src={href}
    style={{ width: "100%", height: "100%", border: "none" }}
  />
}

export function IframePath({
  href,
  ...restProps
}: RouteAndHref & { title?: string }) {
  return (
    <Route
      {...restProps}
      component={() => (<FullIframe title={restProps.title} href={href} />)}
    />
  );
}

export function HashRoute({
  hash,
  ...routeProps
}: Route["props"] & { hash: string }) {
  return (
    <Route
      render={({ location: l }) => l.hash === hash && <Route {...routeProps} />}
    />
  );
}

export function ApiListener() {
  const dispatch = useAppDispatch();
  useEffect(function() {
    return api.on(function(res) {
      switch (res.error) {
        case "INVALID_OR_EXPIRED_TOKEN":
        case "ACCESS_TOKEN_REQUIRED":
          return dispatch({ type: "LOGOUT" });
        default:
          return;
      }
    });
  }, []);
  return null;
}

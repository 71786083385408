import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducers from '../reducers';
import designer from '../reducers/designer/index';
import upgrade from '../reducers/upgrade';
export const store = configureStore({
  reducer: {
    ...reducers,
    designer,
    upgrade
  },
  // This is required until we remove all the Immutable JS
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

function getState(){
	return store.getState()
}
//@ts-ignore
window.store = store;
//@ts-ignore
window.getState = getState

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
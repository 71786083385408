import {
  createAction,
  createSlice,
  PayloadAction,
  CaseReducerWithPrepare,
} from "@reduxjs/toolkit";
import type { RootState } from "../../redux/store";
import { Profile } from "@utm-builder/ui/dist/types";
import {
  uuid,
  reorder,
  moveItem,
  getUnusedCols,
  listHasId,
  basicDataPoint,
  getDefault,
} from "./utils";
import _ from "lodash";

export function getSymbols(seperator: string) {
  seperator = seperator || "_";
  let regex = seperator === "-" ? /-/g : /_/g;
  let spacer = seperator === "-" ? "_" : "-";
  let inputRegex = new RegExp(`^[a-z0-9${spacer}]+$`);
  return {
    regex,
    spacer,
    seperator,
    inputRegex,
    isValid: function(str: string) {
      return inputRegex.test(str);
    },
    replaceSpacer: function(str: string) {
      return str.replace(regex, spacer);
    },
  };
}

export function selectSymbols(state: RootState) {
  const seperator = state.designer.current?.linkSettings?.dataPointSeperator;
  return seperator ? getSymbols(seperator) : undefined;
}

export interface EditorProfile extends Profile.Profile {
  updateId: number;
  temp_id?: string;
  originId?: string;
}

type EditFieldModal =
  | {
    type: "FIELD_DETAILS";
    fieldI: number;
    elementId?: undefined;
  }
  | {
    type: "FIELD_ELEMENT";
    fieldI: number;
    elementId?: number;
  };
export interface HoverAction {
  dest: number;
  origin: number;
  moveType: string;
}

interface DesignerState {
  editFieldModal?: EditFieldModal;
  historyMeta: {
    type: string;
    at: number;
  };
  showSharing: boolean;
  editDatapoint?: {
    id: string;
    datapoint: Profile.Config.Datapoint;
    isEdited?: boolean;
  };
  showWalkthrough: boolean;
  showPreview: boolean;
  loading: boolean;
  error?: string;
  edits: number;
  redirectProfileId?: string;
  past: EditorProfile[];
  current: EditorProfile | null;
  future: EditorProfile[];
}
function previewState(): DesignerState | undefined {
  if (!isPreview()) {
    return;
  }
  const str = localStorage.getItem("designerState");
  localStorage.removeItem("designerState");
  try {
    if (str) {
      return JSON.parse(str);
    }
  } catch (e) {
    console.log(e);
  }
  return;
}
function initState(): DesignerState {
  return (
    previewState() || {
      historyMeta: {
        type: "BLANK",
        at: Date.now(),
      },
      showSharing: false,
      editDatapoint: undefined,
      showWalkthrough: false,
      showPreview: false,
      loading: false,
      edits: 0,
      past: [],
      current: null,
      future: [],
    }
  );
}

function getId({ current }: DesignerState): string | undefined {
  //@ts-ignore
  return current ? current._id || current.temp_id : undefined;
}

function isMe(state: DesignerState, _id: string) {
  return getId(state) === _id;
}

function isBasic(state: DesignerState) {
  return state.current?.configMode !== "advanced";
}

const MIN_CHANGE_TIMEOUT = 400;

function createHistory(state: DesignerState, updateType: string) {
  if (!state.current) {
    return;
  }
  const now = Date.now();
  const hasLast = state.past.length > 0;
  const { at, type } = state.historyMeta;
  state.current.updateId = now;
  state.historyMeta = {
    at: now,
    type: updateType,
  };
  if (updateType === type) {
    return;
  }
  if (hasLast && now - at < MIN_CHANGE_TIMEOUT) {
    return;
  }
  state.edits++;
  state.future = [];
  state.current.updateId = now;
  state.past.push(_.cloneDeep(state.current));
  return;
}

interface GetFieldHistoryP {
  profileId: string;
  fieldId: string;
  data: (string | { value: string })[];
}

function getFieldHistory(state: DesignerState, payload: GetFieldHistoryP) {
  const { profileId, fieldId, data } = payload;
  if (!data || !isMe(state, profileId) || !state.current) {
    return;
  }
  //@ts-ignore
  const history: string[] = (data || []).map((el) => el.value || el);
  const fields = state.current.config.fields.map(function(f) {
    if (f.id !== fieldId) {
      return f;
    }
    f.historySuggestions = _.uniq((f.historySuggestions || []).concat(history));
    return f;
  });
  state.current.config.fields = fields;
}
interface SetFieldHistories {
  profileId: string;
  fieldValues: {
    title: string;
    value: string;
  }[];
}
function setFieldHistories(state: DesignerState, payload: SetFieldHistories) {
  const { profileId, fieldValues } = payload;
  if (!isMe(state, profileId) || !state.current) {
    return;
  }
  //eslint-disable-next-line
  for (let field of fieldValues) {
    let { value, title } = field;
    getFieldHistory(state, {
      data: [value],
      fieldId: title,
      profileId,
    });
  }
  return state;
}

function findCampaignIndex(config: Profile.Config.Config) {
  let i = config.utms.findIndex((u) => /campaign/.test(u.id));
  return i === -1 ? 0 : i;
}

function removeDataPoint(
  state: DesignerState,
  payload: string | { row: number; col: number }
) {
  if (!state.current) {
    return;
  }
  if (typeof payload === "string") {
    return removeAllDataPoints(state, payload);
  }
  const { row, col } = payload;
  const utms = state.current.config.utms;
  let toRemove = utms[row].list[col];
  if (isBasic(state)) {
    removeField(state, toRemove);
    return;
  }
  let count = 0;
  utms.forEach(function(datapoints) {
    datapoints.list.forEach(function(d) {
      if (d === toRemove) count++;
    });
  });
  if (count < 2) {
    removeAllDataPoints(state, toRemove);
    return;
  }
  state.current.config.utms[row].list.splice(col, 1);
}
function removeAllDataPoints(state: DesignerState, name: string) {
  if (!state.current) {
    return;
  }
  state.current.config.utms = state.current.config.utms.map(function(u) {
    u.list = u.list.filter((dp) => dp !== name);
    return u;
  });
  state.current.config.datapoints = state.current.config.datapoints.filter(
    (d) => d.id !== name
  );
}

interface ChangeOwner {
  requestPayload: {
    newOwnerId: string;
  };
  profileId: string;
  data?: any;
}

interface RemoveUserPermission {
  requestPayload: {
    email: string;
    permission: Profile.Permission["permission"];
  };
  profileId: string;
  data?: any;
}

interface AddUserPermission {
  data?: {
    permissions: Profile.Permission[];
  };
  profileId: string;
}

interface ReqCreateProfile {
  temp_id?: string;
  data?: EditorProfile;
  error?: string;
  loading?: boolean;
}

function loadTemplate(
  state: DesignerState,
  params: PayloadAction<ReqCreateProfile>
) {
  const { data: profile, loading = false, error } = params.payload;
  if (!profile) {
    return {
      ...initState(),
      loading,
      error,
    };
  }
  //@ts-ignore
  if (profile.profileType === "fromTemplate") {
    profile.originId = profile._id;
    //@ts-ignore
    profile._id = undefined;
    profile.temp_id = profile.temp_id || uuid();
  }
  profile.linkSettings = {
    ...getDefault("LINKSETTINGS"),
    ...(profile.linkSettings || {}),
  };
  profile.linkCount = {
    remaining: 20,
    total: 0,
    monthly: 0,
  };
  profile.active = true;
  return loadProfile(state, {
    ...params,
    payload: {
      loading: false,
      data: profile,
      error: undefined,
    },
  });
}

function loadProfile(
  state: DesignerState,
  params: PayloadAction<ReqCreateProfile>
): DesignerState | undefined {
  const { data: profile, loading = false, error } = params.payload;
  if (!profile) {
    return {
      ...initState(),
      loading,
      error,
    };
  }
  // @ts-ignore
  if (profile.configMode === "basic") {
    // this is a wrong field that was there sometimes
    //@ts-ignore
    profile.configMode = "normal";
  }
  return {
    ...initState(),
    current: profile,
    loading,
    error,
  };
}
const DES_LOAD_TEMPLATE = createAction<ReqCreateProfile>("DES_LOAD_TEMPLATE");
const UPDATE_PROFILE = createAction<ReqCreateProfile>("UPDATE_PROFILE");
const CREATE_PROFILE = createAction<ReqCreateProfile>("CREATE_PROFILE");
const DES_LOAD_PROFILE = createAction<ReqCreateProfile>("DES_LOAD_PROFILE");
const CONNECT_PROFILE_SHORTENER = createAction<any>(
  "CONNECT_PROFILE_SHORTENER"
);
const MERGE_PROFILE_VALUES = createAction<{ _id: string; linkCount: any }>(
  "MERGE_PROFILE_VALUES"
);
const TOGGLE_ACTIVE_STATUS = createAction<{
  profileId: string;
  active: boolean;
}>("TOGGLE_ACTIVE_STATUS");
const SET_FIELD_HISTORIES = createAction<SetFieldHistories>(
  "SET_FIELD_HISTORIES"
);
const GET_FIELD_HISTORY = createAction<GetFieldHistoryP>("GET_FIELD_HISTORY");

function createProfile(
  state: DesignerState,
  params: PayloadAction<ReqCreateProfile>
): DesignerState | undefined {
  /// if the profile created set the _id
  let { data: profile, temp_id } = params.payload;
  if (!profile) {
    return state;
  }

  const _id = temp_id || profile.temp_id || "__NOT_ME";
  if (isMe(state, _id) && state.current) {
    state.current._id = profile._id;
    state.current.originId = undefined;
    state.current.temp_id = undefined;
    state.current.profileType = "profile";
    state.current.linkCount = profile.linkCount;
    state.redirectProfileId = profile._id;
    state.edits = 0;
    return;
  }
  return loadProfile(state, params);
}

function removeField(state: DesignerState, id: string) {
  if (!state.current) {
    return;
  }
  const { utms, datapoints, fields } = state.current.config;
  if (isBasic(state)) {
    state.current.config.utms = utms.map(function(u) {
      u.list = u.list.filter((l) => l !== id);
      return u;
    });
    state.current.config.datapoints = datapoints.filter((f) => f.id !== id);
  }
  state.current.config.fields = fields.filter((f) => f.id !== id);
}

function timetravel(state: DesignerState, i: number = -1) {
  let { current, past, future, edits } = state;
  state.historyMeta.type = "TIMETRAVEL" + i.toString();
  if (i < 0 && past.length) {
    let newCurrent = past.pop() || null;
    if (current) {
      future.unshift(current);
    }
    if (newCurrent) {
      newCurrent.updateId = Date.now();
    }
    edits--;
    state.future = future;
    state.current = newCurrent;
    state.past = past;
    state.edits = edits;
    return;
  }
  if (future.length) {
    edits++;
    let newCurrent = future.shift() || null;
    if (current) {
      past.push(current);
    }
    if (newCurrent) {
      newCurrent.updateId = Date.now();
    }
    state.future = future;
    state.current = newCurrent;
    state.past = past;
    state.edits = edits;
    return;
  }
}

function updateActionId(
  state: DesignerState,
  payload: { oldId: string; newId: string }
) {
  if (!state.current) {
    return;
  }
  const { oldId, newId } = payload;
  state.current.config.actions.forEach(function(action) {
    if (action.id.toString() === oldId) {
      action.id = newId;
    }
  });
  state.current.config.tests.forEach(function(test) {
    if (test.action.toString() === oldId) {
      test.action = newId;
    }
  });
}

function updateAction(
  state: DesignerState,
  { id, update }: { id: string; update: Profile.Config.Action }
) {
  if (!state.current) {
    return;
  }
  state.current.config.actions = state.current.config.actions.map(function(
    action
  ) {
    return action.id === id ? update : action;
  });
  if (id !== update.id) {
    updateActionId(state, { oldId: id, newId: update.id.toString() });
  }
}
interface FieldRename {
  oldName: string;
  newName: string;
}
function _renameDataPoint(state: DesignerState, data: FieldRename) {
  const { oldName, newName } = data;
  if (oldName === newName || !state.current) {
    return;
  }
  const { utms } = state.current.config;
  state.current.config.utms = utms.map(function(u) {
    u.list = u.list.map(function(li) {
      return li === oldName ? newName : li;
    });
    return u;
  });
}
function renameTests(
  tests: Profile.Config.Ifs[],
  oldName: string,
  newName: string
) {
  return tests.map(function(t) {
    if (t.field === oldName) {
      t.field = newName;
    }
    return t;
  });
}
function _renameField(state: DesignerState, data: FieldRename) {
  const { oldName, newName } = data;
  if (oldName === newName || !state.current) {
    return;
  }
  const basicMode = isBasic(state);
  if (basicMode) {
    _renameDataPoint(state, data);
  }
  let reg = new RegExp("\\$\\{" + oldName + "\\}", "g");
  let actReg = new RegExp("^" + oldName + "($|::)");
  const { datapoints, tests, actions } = state.current.config;
  //FUNNELS
  state.current.config.datapoints = datapoints.map(function(dp) {
    if (basicMode && dp.id === oldName) {
      dp.id = newName;
    }
    dp.reciepes = dp.reciepes.map(function(r) {
      if (reg.test(r.value)) {
        r.value = r.value.replace(reg, "${" + newName + "}");
      }
      r.tests = renameTests(r.tests, oldName, newName);
      return r;
    });
    return dp;
  });
  //TESTS
  state.current.config.tests = tests.map(function(t) {
    t.tests = renameTests(t.tests, oldName, newName);
    return t;
  });
  //actions
  state.current.config.actions = actions.map(function(a) {
    a.actionables = a.actionables.map(function(ac) {
      return actReg.test(ac) ? ac.replace(oldName, newName) : ac;
    });
    return a;
  });
}

function _addFieldElements(
  state: DesignerState,
  payload: { fieldI: number; elements: Profile.Config.FieldButton[] }
) {
  payload.elements.forEach((el) => {
    if (!state.current) {
      return;
    }
    state.current.config.fields[payload.fieldI].fields.push(el);
  });
}

function parseStorage(profile?: EditorProfile) {
  const str = localStorage.getItem("__designer");
  if (!str) {
    return;
  }
  const data: EditorProfile = JSON.parse(str);
  if (data._id === profile?._id && data.updateId === profile?.updateId) {
    return;
  }
  return data;
}

const designerSlice = createSlice({
  name: "designer",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initState(),
  reducers: {
    toggleSharing(state) {
      state.showSharing = !state.showSharing;
    },
    clearRedirect(state) {
      state.redirectProfileId = undefined;
    },
    toggleWalkthrough(state, params: PayloadAction<boolean | undefined>) {
      const data = params.payload;
      state.showWalkthrough =
        data === undefined ? !state.showWalkthrough : !!data;
    },
    /// CORE + MISC
    DES_TIMETRAVEL(state, params: PayloadAction<number>) {
      const direction = params.payload;
      timetravel(state, direction);
    },
    UPDATE_PROFILE_DETAILS(
      state,
      params: PayloadAction<
        Partial<
          Pick<EditorProfile, "_id" | "description" | "title" | "configMode">
        >
      >
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      Object.entries(params.payload).forEach(function([key, value]) {
        //@ts-ignore
        state.current[key as "description" | "title" | "configMode"] = value;
      });
    },
    togglePreivew(state, params: PayloadAction<boolean | undefined>): void {
      state.showPreview =
        params.payload === undefined ? !state.showPreview : !!params.payload;
      if (!state.current) {
        return;
      }
      state.current.updateId = Date.now();
    },
    updateDataPointSeperator(state, params: PayloadAction<string>): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const value = params.payload;
      const seperatorNow =
        state.current.linkSettings?.dataPointSeperator || "_";
      if (seperatorNow === value) {
        // dont do anything
        return;
      }
      state.current.linkSettings = {
        ...getDefault("LINKSETTINGS"),
        ...(state.current.linkSettings || {}),
        dataPointSeperator: value,
      };
      const { replaceSpacer } = getSymbols(value);
      const fields = state.current.config.fields;
      state.current.config.fields = fields.map(function(field) {
        field.fields = field.fields.map(function(el) {
          el.value = replaceSpacer(el.value);
          return el;
        });
        return field;
      });
    },
    updateLinkSettings(
      state,
      params: PayloadAction<Partial<Profile.Config.LinkSettings>>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      state.current.linkSettings = {
        ...getDefault("LINKSETTINGS"),
        ...(state.current.linkSettings || {}),
        ...params.payload,
      };
    },
    updateConvention(state, params: PayloadAction<"ga" | "custom">): void {
      const GA_LIST = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ];
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const convention = params.payload;
      state.current.linkSettings = {
        ...getDefault("LINKSETTINGS"),
        ...(state.current.linkSettings || {}),
        convention,
      };

      let utms = state.current.config.utms;
      if (convention === "ga") {
        GA_LIST.forEach(function(g) {
          if (utms.some((u) => u.id === g)) {
            return;
          }
          utms.push({
            id: g,
            list: [],
          });
        });
      } else {
        utms = utms.filter(function(u) {
          if (u.list.length > 0) {
            return true;
          }
          return !GA_LIST.some(function(g) {
            return g === u.id;
          });
        });
      }
      state.current.config.utms = utms;
    },
    setUuidMethod(
      state,
      params: PayloadAction<"append" | "overwrite" | "none">
    ): void {
      if (!state.current) {
        return;
      }
      const methodName = params.payload;
      createHistory(state, params.type);
      state.current.linkSettings = {
        ...getDefault("LINKSETTINGS"),
        ...(state.current.linkSettings || {}),
        appendMethod: methodName === "overwrite" ? "overwrite" : "append",
        includeUUID: methodName !== "none",
      };
    },
    addUTM(state): void {
      if (!state.current) {
        return;
      }
      createHistory(state, "ADD_UTM");
      const utms = state.current.config.utms;
      const hasAddedUTM = utms.some(function(u) {
        return u && u.id === "custom_utm";
      });
      if (hasAddedUTM) {
        return;
      }
      state.current.config.utms.push(getDefault("UTM"));
    },
    toggleHiddenUTM(state, params: PayloadAction<{ row: number }>): void {
      if (!state.current) {
        return;
      }
      const { row } = params.payload;
      createHistory(state, params.type + row);
      //@ts-ignore
      const isHidden = state.current.config.utms[row].isHidden;
      //@ts-ignore
      state.current.config.utms[row].isHidden = !isHidden;
    },
    renameUTM(
      state,
      params: PayloadAction<{ row: number; value: string }>
    ): void {
      if (!state.current) {
        return;
      }
      const { row, value } = params.payload;
      createHistory(state, params.type + row);
      state.current.config.utms[row].id = value;
    },
    removeUTM(state, params: PayloadAction<number>): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      state.current.config.utms.splice(params.payload, 1);
    },
    removeUTMEl(
      state,
      params: PayloadAction<{ row: number; col: number }>
    ): void {
      if (!state.current) {
        return;
      }
      const { row, col } = params.payload;
      createHistory(state, params.type);
      state.current.config.utms[row].list.splice(col, 1);
    },
    duplicateUTMEl(
      state,
      params: PayloadAction<{ row: number; col: number }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { row, col } = params.payload;
      const el = state.current.config.utms[row].list[col];
      state.current.config.utms[row].list.push(_.cloneDeep(el));
    },
    moveUTMEl(
      state,
      params: PayloadAction<{
        moveType: string;
        origin: { row: number; col: number };
        dest: { row: number; col: number };
      }>
    ): void {
      if (!state.current) {
        return;
      }
      const { dest, origin, moveType } = params.payload;
      if (moveType === "END_DRAG") {
        state.historyMeta.type = "END_DRAG_UTMEL";
        return;
      }
      createHistory(state, moveType);
      // Corner cases happen here were the last element move to the drop area of the last plus 1
      const list = state.current.config.utms[origin.row].list;
      const el = list[origin.col] || list.pop();
      if (!el) {
        return;
      }
      state.current.config.utms[origin.row].list.splice(origin.col, 1);
      state.current.config.utms[dest.row].list.splice(dest.col, 0, el);
    },
    addTest(
      state,
      params: PayloadAction<{ i: number; action?: Profile.Config.Action }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { i, action = getDefault("ACTION") } = params.payload;
      let maxId = 1;
      state.current.config.actions.forEach(function(a) {
        let id = a.id.toString();
        if (/^action_/.test(id)) {
          let n = parseInt(id.replace("action_", ""));
          if (!isNaN(n) && n > maxId) {
            maxId = n;
          }
        }
      });
      action.id = "action_" + (maxId + 1);
      const test = getDefault("TEST");
      test.action = action.id;
      state.current.config.tests.splice(i, 0, test);
      const actions = state.current.config.actions.filter(
        (a) => a.id !== action.id
      );
      actions.push(action);
      state.current.config.actions = actions;
    },
    duplicateTest(state, params: PayloadAction<number>): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const testI = params.payload;
      const test = _.cloneDeep(state.current.config.tests[testI]);
      //@ts-ignore
      delete test._id;
      test.id = getDefault("TEST").id;
      state.current.config.tests.splice(testI, 0, test);
    },
    updateTest(
      state,
      params: PayloadAction<{ testI: number; test: Profile.Config.Test }>
    ): void {
      if (!state.current) {
        return;
      }
      const { test, testI } = params.payload;
      createHistory(state, params.type + testI);
      state.current.config.tests[testI] = test;
    },
    removeTest(state, params: PayloadAction<number>): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      state.current.config.tests.splice(params.payload, 1);
    },
    reorderTests(
      state,
      params: PayloadAction<{ i: number; direction: number }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { i, direction } = params.payload;
      state.current.config.tests = reorder(
        state.current.config.tests,
        i,
        direction as 1 | -1
      );
    },
    updateAction(
      state,
      params: PayloadAction<{ id: string; update: Profile.Config.Action }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type + params.payload.id);
      updateAction(state, params.payload);
    },
    updateActionId(
      state,
      params: PayloadAction<{ oldId: string; newId: string }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      updateActionId(state, params.payload);
    },
    moveFieldElement(
      state,
      params: PayloadAction<{
        moveType: string;
        fieldI: number;
        origin: number;
        dest: number;
      }>
    ): void {
      if (!state.current) {
        return;
      }
      const { dest, origin, moveType, fieldI } = params.payload;
      const fields = state.current.config.fields[fieldI]?.fields;
      if (!fields) {
        return;
      }
      if (moveType === "END_DRAG") {
        state.historyMeta.type = "END_DRAG";
        return;
      }
      createHistory(state, moveType);
      state.current.config.fields[fieldI].fields = moveItem(
        fields,
        origin,
        dest
      );
    },
    removeFieldElement(
      state,
      params: PayloadAction<{ fieldI: number; elementId: number }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { fieldI, elementId } = params.payload;
      state.current.config.fields[fieldI].fields.splice(elementId, 1);
      state.editFieldModal = undefined;
    },
    updateFieldElement(
      state,
      params: PayloadAction<{
        elementId: number;
        fieldI: number;
        element?: Profile.Config.FieldButton;
      }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      let { elementId, fieldI, element } = params.payload;
      if (element === undefined) {
        return; //removeFieldElement(state,params);
      }
      state.current.config.fields[fieldI].fields[elementId] = element;
    },
    addFieldElements(
      state,
      params: PayloadAction<{
        fieldI: number;
        elements: Profile.Config.FieldButton[];
      }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      _addFieldElements(state, params.payload);
    },
    addFieldElement(
      state,
      params: PayloadAction<{
        fieldI: number;
        element?: Profile.Config.FieldButton;
      }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { fieldI, element = getDefault("FIELD_ELEMENT") } = params.payload;
      _addFieldElements(state, { fieldI, elements: [element] });
      if (element.value === getDefault("FIELD_ELEMENT").value) {
        state.editFieldModal = {
          type: "FIELD_ELEMENT",
          fieldI,
          elementId: state.current.config.fields[fieldI].fields.findIndex(
            (v) => v.value === element.value
          ),
        };
      }
    },
    openEditFieldModal(
      state,
      params: PayloadAction<DesignerState["editFieldModal"]>
    ): void {
      state.editFieldModal = params.payload;
    },
    closeEditFieldModal(state): void {
      if (!state.current || !state.editFieldModal) {
        return;
      }
      const { type, fieldI } = state.editFieldModal;
      const field = state.current.config.fields[fieldI];
      if (type === "FIELD_DETAILS" && /newField|newDataPoint/.test(field.id)) {
        timetravel(state, -1);
      }
      state.editFieldModal = undefined;
    },
    addField(
      state,
      params: PayloadAction<{ index: number; edit?: boolean }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { index, edit } = params.payload;
      const FIELD = getDefault("FIELD");
      const { fields, datapoints, utms } = state.current.config;
      if (isBasic(state)) {
        const utmIndex = findCampaignIndex(state.current.config);
        const utmList = utms.reduce(function(out, curr) {
          return out.concat(curr.list);
        }, [] as string[]);
        const id = "newDataPoint";
        FIELD.id = id;
        const newDataPoint = basicDataPoint(id);
        //@ts-ignore
        newDataPoint.col_id = getUnusedCols(datapoints)[1];
        if (!utmList.some((u) => u === id)) {
          state.current.config.utms[utmIndex].list.push(id);
        }
        if (!listHasId(id, datapoints)) {
          state.current.config.datapoints.push(newDataPoint);
        }
      }
      if (!listHasId(FIELD.id, fields)) {
        fields.splice(index, 0, FIELD);
        state.current.config.fields = fields;
      }
      if (edit) {
        state.editFieldModal = {
          type: "FIELD_DETAILS",
          fieldI: index,
        };
      }
    },
    reorderFields(state, params: PayloadAction<HoverAction>): void {
      if (!state.current) {
        return;
      }
      const { dest, origin, moveType } = params.payload;
      if (moveType === "END_DRAG") {
        state.historyMeta.type = "END_DRAG";
        return;
      }
      createHistory(state, moveType);
      state.current.config.fields = moveItem(
        state.current.config.fields,
        origin,
        dest
      );
    },
    updateField(
      state,
      params: PayloadAction<{ i: number; field: Profile.Config.Field }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { i, field: update } = params.payload;
      const field = state.current.config.fields[i];
      _renameField(state, {
        oldName: field.id,
        newName: update.id,
      });
      state.current.config.fields[i] = update;
      if (update.type === "buttons" && !update.fields?.length) {
        state.editFieldModal = {
          type: "FIELD_ELEMENT",
          fieldI: i,
        };
        return;
      }
      state.editFieldModal = undefined;
    },
    removeField(state, params: PayloadAction<number | string>): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      state.editFieldModal = undefined;
      const index = params.payload;
      if (typeof index === "number") {
        removeField(state, state.current?.config.fields[index].id);
        return;
      }
      removeField(state, index);
    },
    removeDatapoint(
      state,
      params: PayloadAction<string | { row: number; col: number }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      removeDataPoint(state, params.payload);
      state.editDatapoint = undefined;
    },
    updateDatapoint(
      state,
      params: PayloadAction<{ id: string; datapoint: Profile.Config.Datapoint }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      let { id, datapoint: update } = params.payload;
      if (id !== update.id) {
        _renameDataPoint(state, {
          oldName: id,
          newName: update.id,
        });
      }
      const datapoints = state.current.config.datapoints.map(function(d) {
        return d.id === id ? _.cloneDeep(update) : d;
      });
      state.current.config.datapoints = datapoints;
    },
    setEditDatapoint(
      state,
      params: PayloadAction<Partial<Profile.Config.Datapoint>>
    ): void {
      if (!state.editDatapoint) {
        return;
      }
      state.editDatapoint.datapoint = {
        ...state.editDatapoint.datapoint,
        ...params.payload,
      };
      state.editDatapoint.isEdited = true;
    },
    setDatapointId(state, params: PayloadAction<string | undefined>): void {
      if (!state.current) {
        return;
      }
      const datapointId = params.payload;
      if (!datapointId || datapointId === state.editDatapoint?.id) {
        state.editDatapoint = undefined;
        return;
      }
      let datapoint = _.cloneDeep(
        state.current.config.datapoints.find((f) => f.id === datapointId)
      );
      if (!datapoint) {
        console.log("Datapoint Error");
        return;
      }
      if (datapoint.id === "tempVar") {
        datapoint.id = "";
      }
      state.editDatapoint = {
        id: datapointId,
        datapoint,
      };
    },
    addDataPoint(
      state,
      params: PayloadAction<{ i: number; value: string }>
    ): void {
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      const { i = findCampaignIndex(state.current.config), value } =
        params.payload;
      const datapoint = getDefault("DATAPOINT");
      const id = value || datapoint.id;
      datapoint.id = id;
      let utmList = state.current.config.utms[i].list;
      if (utmList.some((l) => l === id)) {
        return;
      }
      state.current.config.utms[i].list.push(id);
      if (!listHasId(id, state.current.config.datapoints)) {
        state.current.config.datapoints.push(datapoint);
      }
    },
    ADD_URL_RULE(state): void {
      if (!state.current) {
        return;
      }
      createHistory(state, "ADD_URL_RULE");
      state.current.config.url.reciepes.push({
        tests: [],
        value: "na",
      });
      return;
    },
    REORDER_URL_RULES(
      state,
      params: PayloadAction<{ i: number; direction: 1 | -1 }>
    ): void {
      const { i, direction } = params.payload;
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      state.current.config.url.reciepes = reorder(
        state.current.config.url.reciepes,
        i,
        direction
      );
    },
    REMOVE_URL_RULES(state, params: PayloadAction<number>): void {
      const i = params.payload;
      if (!state.current) {
        return;
      }
      createHistory(state, params.type);
      state.current.config.url.reciepes.splice(i, 1);
    },
    UPDATE_URL_RULE(
      state,
      params: PayloadAction<{ i: number } & Profile.Config.Reciepe>
    ): void {
      if (!state.current) {
        return;
      }
      const { i, ...reciepe } = params.payload;
      createHistory(state, params.type);
      state.current.config.url.reciepes[i] = reciepe;
    },
    ADD_USER_PERMISSION(state, params: PayloadAction<AddUserPermission>): void {
      const { profileId, data } = params.payload;
      if (!data || !isMe(state, profileId) || !state.current) {
        return;
      }
      state.current.permissions = data.permissions;
    },
    CHANGE_OWNER(state, params: PayloadAction<ChangeOwner>) {
      if (!params.payload.data) {
        return;
      }
      const { requestPayload, profileId } = params.payload;
      if (!state.current || !isMe(state, profileId)) {
        return;
      }
      state.current.permissions = state.current.permissions.map(function(el) {
        if (requestPayload.newOwnerId === el._id) {
          el.permission = "owner";
          return el;
        } else if (el.permission === "owner") {
          el.permission = "editor";
          return el;
        }
        return el;
      });
    },
    REMOVE_USER_PERMISSION(state, params: PayloadAction<RemoveUserPermission>) {
      if (!params.payload.data) {
        return;
      }
      let { requestPayload, profileId } = params.payload;
      if (!isMe(state, profileId) || !state.current) {
        return;
      }
      state.current.permissions = state.current?.permissions.filter(function(
        permission
      ) {
        return permission.email !== requestPayload.email;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UPDATE_PROFILE, createProfile)
      .addCase(CREATE_PROFILE, createProfile)
      .addCase(DES_LOAD_PROFILE, loadProfile)
      .addCase(DES_LOAD_TEMPLATE, loadTemplate)
      .addCase(
        CONNECT_PROFILE_SHORTENER,
        function connectShortener(state, params) {
          let { data: profile, profileId } = params.payload;
          if (!profile || !isMe(state, profileId) || !state.current) {
            return;
          }
          //@ts-ignore
          state.current.shortener = profile.shortener;
          state.current.updateId = Date.now();
        }
      )
      .addCase(MERGE_PROFILE_VALUES, function(state, params) {
        if (!isMe(state, params.payload._id) || !state.current) {
          return state;
        }
        state.current.updateId = Date.now();
        Object.entries(params.payload).forEach(function([key, value]) {
          if (key === "_id") return;
          //@ts-ignore
          state.current[key] = value;
        });
      })
      .addCase(TOGGLE_ACTIVE_STATUS, function(state, params) {
        if (!state.current || !isMe(state, params.payload.profileId)) {
          return;
        }
        state.current.updateId = Date.now();
        state.current.active = params.payload.active;
      })
      .addCase(GET_FIELD_HISTORY, function(state, params) {
        if (!state.current || !params.payload.data) return;
        state.current.updateId = Date.now();
        return getFieldHistory(state, params.payload);
      })
      .addCase(SET_FIELD_HISTORIES, function(state, params) {
        if (!state.current) return;
        state.current.updateId = Date.now();

        return setFieldHistories(state, params.payload);
      })
      .addMatcher(
        () => true,
        function(state, action) {
          if (isPreview()) {
            return;
          }
          localStorage.setItem("windowdispatch", JSON.stringify(action));
        }
      );
  },
});

function isPreview() {
  const href = location.href;
  return href.includes("preview");
}
export const actions = designerSlice.actions;

export function selectUser(state: RootState) {
  return state.userState?.user;
}

export function selectProfile(state: RootState) {
  return state.designer.current;
}

export function selectProfileIsEnterprise(state: RootState) {
  return (selectProfile(state)?.plan || -1) === 2;
}

export function selectConfig(state: RootState) {
  return selectProfile(state)?.config;
}
export function selectFields(state: RootState) {
  return selectConfig(state)?.fields;
}
export function selectFieldById(id: string) {
  return (state: RootState) => selectFields(state)?.find((f) => f.id === id);
}
export function selectActionByTestIndex(i: number) {
  return function(state: RootState) {
    const test = selectTestByIndex(i)(state);
    if (!test) {
      return;
    }
    const actions = selectActionMap(state);
    return actions[test.action];
  };
}

export function selectActionMap(state: RootState) {
  const actions: Record<string, Profile.Config.Action> = {};
  selectConfig(state)?.actions.forEach(function(action) {
    actions[action.id] = action;
  });
  return actions;
}
export function selectTestByIndex(i: number) {
  return (state: RootState) => selectConfig(state)?.tests[i];
}
export function selectTests(state: RootState) {
  return selectConfig(state)?.tests;
}
//@ts-ignore
window.reduxSlice = designerSlice;
export default designerSlice.reducer;

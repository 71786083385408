import React from 'react';
import {Redirect} from 'react-router-dom'
import {withRouter} from 'react-router'
import { connect } from 'react-redux'

const TUTORIAL_STATUS = ['new','incomplete'];
export function EnsureLoginContainer(props) {
  const { 
    children,
    shouldLogin,
    redirectToTutorialStep
  } = props;
  /// if there is no user or you are trying to access an Admin route redirect
  if(shouldLogin){
    const redirectTo = {
      pathname: '/login',
      state: { from: props.location } 
      // state will end up under props.location.state 
      // and can be used to bring the user back to what they actually were seeking
    };
    return <Redirect to={redirectTo}/>
  }

  // don't redirect to the place that you are
  if( typeof redirectToTutorialStep === 'string' ){
      const redirectTo = {
        pathname:'/app/tutorial/' + redirectToTutorialStep,
        state: { from: props.location }
      };
      return <Redirect to={redirectTo}/>
  }
  // otherwise render through
  return children
}
function getTutorialRedirect({companyDetails,pathname,onboardingStatus='legacy-user'}){
  if(pathname.includes('tutorial')){
    return null
  }
  if(onboardingStatus === 'legacy-user'){
    return null
  }
  const hasCompanyName = !!(companyDetails||{}).name
  if(!hasCompanyName){
    return '0'
  }
  if(!TUTORIAL_STATUS.includes(onboardingStatus)){
    return null
  }
  return '1'
}
EnsureLoginContainer.mapState = function toState(state,props){
  const user = state.userState.user;
  const { location, level='basic' } = props;
  if(!user){
    return { shouldLogin:true }
  }
  
  const { pathname } = location;
  const { companyDetails = {}, onboarding = {} } = user;
  return {
    shouldLogin:(!user.admin && level === 'admin'),
    redirectToTutorialStep:getTutorialRedirect({
      companyDetails,
      pathname,
      onboardingStatus:onboarding.questionaire
    })
  }
}
export default withRouter(connect(EnsureLoginContainer.mapState)(EnsureLoginContainer));





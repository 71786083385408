import Sentry from "../sentry";

const MODELS_LIST = [
  "subscriptions",
  "stripe",
  "profiles",
  "users",
  "links",
  "shortLinks",
  "templates",
  "events",
  "texts",
  "domains",
  "coupons",
  "chat",
] as const;
type Model = (typeof MODELS_LIST)[number];

export type APIRes<T = any> =
  | {
      error?: undefined;
      stack?: undefined;
      data: T;
    }
  | {
      error: string;
      stack: string;
      data?: null;
    };

export async function post<Req = any, Res = any>(
  url: string,
  payload: Req,
): Promise<APIRes<Res>> {
  let e = new Error("REQUEST FAILED");
  try {
    const headers = new Headers();
    headers.set("Content-Type", "application/json");
    const reqInfo: RequestInit = {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, reqInfo);
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    const data = {
      error: "SERVER_ERROR",
      stack: e.stack || "Unknown Stack",
      data: null,
    };
    return data;
  }
}

interface APIReq<T = any> {
  type: Model;
  action: string;
  request: T;
  admin?: boolean;
}

function _api<T, Req = any>(data: APIReq<Req>) {
  return post<APIReq<Req>, T>("/api/v0.1", data);
}

type Listener = (res: APIRes) => void;
class API {
  _listeners: Listener[];
  _cache: Partial<Record<Model, Record<string, any>>>;
  _isAdmin: boolean;
  _isCached: boolean;
  constructor() {
    this._cache = {};
    this._isCached = false;
    this._isAdmin = false;
    this._listeners = [];
  }
  on(fn: Listener) {
    this._listeners.push(fn);
    return () => {
      this._listeners = this._listeners.filter((v) => v !== fn);
    };
  }
  clearCache(model?: Model) {
    if (!model) {
      this._cache = {};
      return;
    }
    this._cache[model] = {};
  }
  _fromCache(model: Model, id: string) {
    return this._cache[model]?.[id];
  }
  _setCache(model: Model, id: string, res: APIRes) {
    if (!this._cache[model]) {
      this._cache[model] = {};
    }
    //@ts-ignore
    this._cache[model][id] = res;
  }
  get cached() {
    this._isCached = true;
    return this;
  }
  get admin() {
    this._isAdmin = true;
    return this;
  }
  emit(res: APIRes) {
    for (let fn of this._listeners) {
      fn(res);
    }
  }
  async req<T = any, Req = any>(
    req: APIReq<Req>,
    options?: ReqOptions,
  ): Promise<APIRes<T>> {
    const _isCached = options?.isCached ?? this._isCached;
    const _isAdmin = options?.isAdmin ?? this._isAdmin;
    const payload = {
      ...req,
      admin: _isAdmin,
    };
    const id = JSON.stringify(payload);
    this._isAdmin = false;
    this._isCached = false;

    if (_isCached && this._fromCache(payload.type, id)) {
      return this._fromCache(payload.type, id);
    }
    const res = await _api<T, Req>(req);
    this.emit(res);
    if (res.data) {
      this._setCache(payload.type, id, res);
    }
    return res;
  }
  async auth<T = any>(data: any): Promise<T> {
    const res = await post("/auth", data);
    this.emit(res);
    // @ts-ignore
    return res;
  }
  conventions<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    const model: Model = "profiles";
    return this.req<T, Req>(
      {
        type: model,
        action,
        request,
      },
      options,
    );
  }
  profiles<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.conventions<T, Req>(action, request, options);
  }
  coupons<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "coupons",
        action,
        request,
      },
      options,
    );
  }
  domains<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "domains",
        action,
        request,
      },
      options,
    );
  }
  events<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "events",
        action,
        request,
      },
      options,
    );
  }
  links<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "links",
        action,
        request,
      },
      options,
    );
  }
  shortLinks<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "shortLinks",
        action,
        request,
      },
      options,
    );
  }
  stripe<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "stripe",
        action,
        request,
      },
      options,
    );
  }
  subscriptions<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "subscriptions",
        action,
        request,
      },
      options,
    );
  }
  templates<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "templates",
        action,
        request,
      },
      options,
    );
  }
  texts<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "texts",
        action,
        request,
      },
      options,
    );
  }
  users<T = any, Req = any>(
    action: string,
    request: Req,
    options?: ReqOptions,
  ) {
    return this.req<T, Req>(
      {
        type: "users",
        action,
        request,
      },
      options,
    );
  }
}
interface ReqOptions {
  isAdmin?: boolean;
  isCached?: boolean;
}

type MethodFn<T = any> = (action: string, request: T) => ReturnType<API["req"]>;
const api = new API();
export default api;

export function track(
  event_category: string,
  event_action: string,
  event_label?: string,
  event_value?: string,
  event_property?: string,
) {
  const event = {
    app_id: "web-app",
    url: window.location.href,
    event_at: new Date(),
    event_type: "structured-event",
    event_category,
    event_action,
    event_label,
    event_value,
    event_property,
  };
  api.events("create", [event]);
}
//@ts-ignore
window._api = api;
//@ts-ignore
window.trackEvent = track;

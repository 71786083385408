const INITIAL_STATE = {
    copyState:null,
    copyId:null
}
function initState(){
	return {...INITIAL_STATE};
}

function getURL(state, data){
    const {id,copyState} = data
    if(copyState === 'COPYING'){
        state = { ...state, copyId:id}
    }
    if(state.copyId !== id){
        return state
    }
    return { ...state, copyState };
}


function contactSupport(state = initState(),action){
	const {type,data} = action
	switch(type){
        case 'GET_URL':
            return getURL(state,data)
		default:
			return state
	}
}	

export default contactSupport

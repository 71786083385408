import auth from './auth'
import user from './user'
import basicBuilder from './basicBuilder'
import notifications from './notifications'
import subscriptions from './subscriptions'
import profiles from './profiles'
import contactSupport from './contactSupport'
import templates from './templates'
import builder from './builder'
import dashboardView from './dashboardView';
import profileSharing from './profileSharing';

export default {
	builder,
	auth,
	userState:user,
	notifications,
	subscriptions,
	profiles,
	templates,
	basicBuilder,
	dashboardView,
	profileSharing,
	contactSupport
}
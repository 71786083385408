import React, { Component, useState, useMemo, useRef } from 'react';
import ButtonGroup from './ButtonGroup'

import './bootstrap.css';
import { ReactElement } from 'react';

/////////////////
export {ButtonGroup}
/// TODO - move to utils type module
export function removeProps(list: string, props: any){
	const parts = list.split(/[\s,\t\n]+/g).map(v=>v.trim());
	let obj: Record<string, any> = {};
	for(var k in props){
		if(!parts.includes(k)) obj[k] = props[k];
	}
	return obj
}
function getColors(props: any){
	const {
		prim,danger,warning,info,success,secondary
	} = props;
	let deco = prim ? 'primary' :
			success ? 'success' :
			secondary ? 'secondary' :
			danger ? 'danger' :
			info ? 'info' :
			warning ? 'warning' : '';
	return deco
}
function getSize(props: any){
	const {xs,sm,md,lg,xl} = props;
	return xl ? 'xl' : 
		lg ? 'lg' :
		md ? 'md' :
		sm ? 'sm' :
		xs ? 'xs' : '' 
}
export function Panel(props: any){
	return <div className="panel panel-default">
	  	<div className="panel-heading">{props.title}</div>
	  	<div className="panel-body"> 
			{props.children}
		</div>
	</div>
}

export type SelectOption = string | {
	value: string;
	text: string;
	disabled?: boolean;
}

export function RadioSelect(props: any){
	const {
		selectOptions,
		vertical=false,
		onChange,
		name='',
		...restProps
	} = props;
	const options = selectOptions.map(function(el: SelectOption) {

		const value = typeof el === 'string' ? el : el.value 
		const text = typeof el === 'string' ? el : el.text 
		const disabled = typeof el === 'string' ? false : el.disabled
		let id = name+value
		let inputProps ={
			id,
			value,
			name,
			disabled:disabled || false,
			checked:value === props.value,
			onChange
		}
		return <label className={vertical ? 'radio' : "radio-inline"} key={value}>
			<input className="custom-control-input radio-inline" type="radio"  {...inputProps}/>
			{' '}{text}
		</label>
	})
	return <div className="form-group" {...restProps}>
		{options}
	</div>
}

export function NavTabs(props: {onChange:(value: string)=>void, value: string, tabs: Array<string | {value: string, child: ReactElement}> }){
    const {onChange,value,tabs} = props;
    return <ul className="nav nav-tabs">
        {tabs.map(function(tab: any){
            let tabValue = tab.value || tab
            let tabChild = tab.child ||tabValue
            let isActive = tabValue === value;
            let onClick = isActive ? undefined : ()=>onChange(tabValue)
            return <li key={tabValue} className={isActive ? "active" : ''} onClick={onClick}>
                <span className='anchor'>{tabChild}</span>
            </li>
        })}
    </ul>
}
export const TextArea = React.forwardRef((props:any, ref: any)=>{
	const style = useMemo(function(){
		const style = Object.assign({
			width: "100%",
			padding: "30px",
			textAlign: "center",
			height: "100px",
			borderRadius: "20px",
			border: "dashed",
		},props.style||{})
		return style
	},[props.style])
	return <textarea {...props} style={style} ref={ref}/>
})
class InputSelectEl extends React.Component<any, any> {
  _makeSelectOptions(){
	return this.props.selectOptions.map(function(o: any){
		let value = o.value || o;
		return <option key={value} value={value}>
			{o.text || o}
		</option>
	});
  }
  render(){
    const {
      hasError,error,hasSucess,inputRef,
      label,style,className='',
      wrapperClass ='',disabled
    } = this.props;
    let clList = ["form-group"]
    if(disabled){
		clList.push('disabled')
	};
    if(wrapperClass){
      clList.push(wrapperClass);
    }
    if(hasSucess){
      clList.push('has-success');
    }else if(hasError){
      clList.push('has-error');
    }
    let size = getSize(this.props);
    if(size){
      clList.push('form-group-'+size);
    }
    const selProps = removeProps('sm,selectOptions,inputRef,wrapperClass,hasError,hasSucess,error,className',this.props);
    selProps.className = 'form-control '+(className||'');
    return <div className={clList.join(' ')} style={style||{}}>
      {label && <label>{label}</label>}
      <select {...selProps} ref={inputRef}>
        {this._makeSelectOptions()}
      </select>
	  {error && <div className="help-block text-danger">{error}</div>}
    </div>
  }
}

function InputEl(props:any){
	let {
		label,error,className='',inputProps={},
		onValue,disabled=false, inputRef
	} = props;
	let cl =  ["form-group"]
	'error,success'.split(',').some(function(el){
		if(props[el]){
			cl.push('has-'+el);
			return true
		}
		return false
	});
	if(disabled){
		cl.push('disabled')
	};
	cl.push(className);
	'sm,xs,md,lg'.split(',').some(k=>{
		if(props[k]){
			cl.push('form-group-'+k)
			return true;
		};
		return false
	});
	inputProps = removeProps(`inputRef,sm,xs,md,lg,label,inputProps,error,hasError,hasSucess,errorOnBlur,errorText,prim,success,danger,warning,active,secondary`,props);
	inputProps.className = 'form-control';
	if(onValue){
		inputProps.onChange = function(e: any){
			return onValue(e.target.value,e);
		}
	}
	return (<div className={cl.join(' ')}>
	    {label && <label>{label}</label>}
	    <input {...inputProps} ref={inputRef}/>
	    {error && <div className="help-block text-danger">{error}</div>}
	</div>)
}
const Input = React.forwardRef((props:any ,ref: any)=>{
	const {selectOptions} = props;
	return selectOptions ? <InputSelectEl {...props} inputRef={ref} /> : <InputEl {...props} inputRef={ref}/>
})
export function Dots(props: any){
	let {el:El='div',className='',...restProps} = props;
	let classList = ['wave-dots'] 
	'sm,xs'.split(',').some(k=>{
		if(restProps[k]){
			delete restProps[k]
			classList.push(k)
			return true
		}
		return false
	})
	classList.push(className)
	return <El className={classList.join(' ')}  {...restProps}>
	    <span className="dot"/>
	    <span className="dot"/>
	    <span className="dot"/>
	</El>
}
export {Input};
export function FormInput(props: any){
	const [blurred, setBlurred] = useState(false)
	const {
		disabled,
		className = '',
		errorText = '',error=false,
		label='',
		errorOnBlur
	} = props;
	let isError = ( (errorText || error) && (blurred || !errorOnBlur) )
	let myprops = removeProps(`sm,xs,md,lg,label,error,errorOnBlur,errorText,prim,success,danger,warning,disabled,active,secondary`,props)	
	let cl = 'form-group '
	if(disabled){
		cl+= ' disabled'
	};
	if(isError){
		cl+= 'has-error'
	} 
	cl+=className;
	let inCl = 'form-control ';
	'sm,xs,md,lg'.split(',').some(k=>{
		if(props[k]){
			inCl += 'input-'+k
			return true;
		}
		return false
	});
	const onBlur = ()=>{
		setBlurred(false)
	};
	return (<div className={cl}>
		{label && <label className="control-label">{label}</label>}
		<input  {...myprops} className={inCl} onBlur={onBlur}/>
	{isError && errorText ? <span className="help-block">{errorText}</span> : null}
	</div>)
}
function Container(props: any){
	const {className='',fluid,background} = props;
	if(background){
		let onProps = removeProps('background',props);
		return <div className={background}>
			<Container {...onProps}/>
		</div>
	}
	let cl =( fluid ? 'container-fluid ' : 'container ')+className
	let onProps = removeProps('className,fluid',props);
	return (<div {...onProps} className={cl} />)
}
function Row(props: any){
	const {className=''} = props;
	let cl = 'row '+className
	let onProps = removeProps('className',props);
	return (<div {...onProps} className={cl} />)
}
function Col(props: any){
	const {n=12,s='md',className=''} = props;
	let onProps = removeProps('n,s',props)
	let cl = `col-${s}-${n} ${className}`;
	return (<div {...onProps} className={cl}/>)
}
export {Col,Row,Container}
export function RowCol(props: any){
	let onProps = removeProps('children',props)
	return <Row {...onProps}>
		<Col>{props.children}</Col>
	</Row>
}

function BtnRender(props: any){
	const {
		sm,xs,lg,
		prim,danger,warning,success,secondary,noBtnType,outline,
		disabled, active,
		className = '',
		allowDisabledClick, // onClick is autodisconnected as a convience (sometimes its not a convience)
		...outProps
	} = props;
	let size = 
		sm !== undefined ? 'btn-sm' :
		xs !== undefined ? 'btn-xs' :
		lg !== undefined ? 'btn-lg' : '';

	let deco = prim ? 'btn-primary' :
		secondary ? 'btn-secondary':
		success ? 'btn-success' :
		danger ? 'btn-danger' :
		warning ? 'btn-warning' : 
		outline ? 'btn-outline' :
		noBtnType ? '' : /*'btn-none'*/ '';
	let cl = `btn ${size} ${deco} ${className}`;
	if(disabled){
		cl +=' disabled'
		if(!allowDisabledClick){
			outProps.onClick = null;
		};
	}
	if(active) cl+= ' active';
	return (<button {...outProps} className={cl}/>)
}
const Btn = BtnRender;
const Button = BtnRender
export {Button,Btn}

export function Close(props: any){
	const {
		className = ''
	} = props;
	let cl = `close ${className}`;
	return (<span {...props} className={cl}>&times;</span>)
}
export function Li(props: any){
	let deco = getColors(props);
	let { className = '',clickable,style,active} = props;
	if(clickable){
		className += ' list-group-item-clickable'
	}
	if(active){
		className += ' active';
	}
	let myprops = removeProps(`info,prim,danger,warning,success,secondary,clickable,active`,props)
	let cl = `list-group-item list-group-item-${deco} ${className}`;
	return (<div {...myprops} style={style} className={cl}/>)
}
const FA_BRAND = ['chrome','google-plus','google','stripe'];
export function Fa(props: any){
	const {
		tag='span',
		className='',
		x='1',
		i='save',
		s=false,b=false,
		spin=false
	} = props;
	const TagName = tag;
	let fa = b ? 'fab' : s ? 'fas' : FA_BRAND.includes(i.split(' ')[0]) ? 'fab' : 'fa';
	let cl = `${fa} fa-${x}x fa-${i} `+className
	if(spin) cl+= ' fa-spin '
	let myprops = removeProps('className,tag,x,i,s,b,spin', props);
	return (<TagName {...myprops} className={cl} />)
}

export function IsOk(props: any){
	const out = {
		i:props.ok ? 'check' : 'times',
		alt:props.ok ?'TRUE':'FALSE',
		className:props.ok?'text-success':'text-danger'
	}
	if(props.className){
		out.className += props.className
	}
	let onProps = removeProps(`ok`,props)
	return <Fa {...onProps} {...out}/>
}
export function FaLoading(props: any){
	return (<Fa {...props} style={{color:'#1e4dc1'}} i='spinner fa-spin' />)
}
	

export function Badge(props: any){
	let deco = getColors(props);
	let size = getSize( props );
	const {
		className = '',tag='span',
	} = props;
	const TagName = tag;
	let myprops = removeProps(`info,prim,danger,warning,success,secondary,tag,xs,sm,md,lg,xl`, props)
	let cl = `badge badge-${deco} badge-${size} ${className}`;
	return (<TagName {...myprops} className={cl} />)
}

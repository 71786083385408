import React from 'react';
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Fa } from '../components/bootstrap'

import '../css/alert.css';

function Close({id,dispatch}){
    const close = _=>dispatch({type:'hideAlert',data:id})
    return <span className='close' style={{color:'white', lineHeight: '18px', opacity: '1'}} onClick={close}>
        &times;
    </span>
}
function Alert(props){
    const {type,hidden,text} = props;
    return <CSSTransition in={!hidden} className={"moving-msg msg msg-"+type} timeout={4000} >
        <div>
            {text} <span style={{paddingRight:'5px'}}/><Fa i="info"/>
            <Close {...props}/>
        </div>
    </CSSTransition>
}
const CONTAINER_STYLE = {
    zIndex:51,
    position:"fixed",
    bottom:"0px",
    right:'0px',
    padding:"0 20px 0 20px",
    width:"350px"
}
const Alerts = connect(state=>{
    const {notifications} = state.notifications;
    return {notifications}
})(function Alerts({dispatch,notifications}){
    return <div style={CONTAINER_STYLE}>
        <TransitionGroup className="todo-list">
        {notifications.map(function(alert){
            return <Alert {...alert} dispatch={dispatch}/>
        })}
        </TransitionGroup>
    </div>
})


export default Alerts






const INITIAL_STATE = {
    type: 'CONTACT', // 'UPGRADE', 'SHORTENER'
    profileId:'',
    profileTitle:'',
    userMessage:'',
    reqContactSupport:{
        loading:false,error:null,data:null,stage:null
    }
}
function initState(){
	return {...INITIAL_STATE};
}

function setForm(state, data){
    return { ...initState(), ...data };
}

function setMessage(state, data){
    return {...state, userMessage:data}
}

function sendContactReq(state, data){
    let {reqContactSupport} = state
    reqContactSupport = {...reqContactSupport, ...data}
    return { ...state, reqContactSupport }
}
function contactSupport(state = initState(),action){
	const {type,data} = action
	switch(type){
        case 'INIT_CONTACT_SUPPORT':
            return setForm(state,data)
        case 'SET_CONTACT_MESSAGE':
            return setMessage(state, data)
        case 'SEND_CONTACT_REQUEST':
            return sendContactReq(state, data)
		default:
			return state
	}
}	

export default contactSupport

import { useEffect } from 'react';
import {  Redirect } from 'react-router-dom'
import api from '../utils/api'
import { useAppDispatch, useAppSelector } from '../redux/hooks';


export default function Logout(){
  const dispatch = useAppDispatch()
  const user = useAppSelector(function(state){
    return state.userState.user
  })
  useEffect(function(){
    localStorage.removeItem('tutorialSkipped');
    localStorage.removeItem('basicBuilderState');
    api.auth({type:'logout', action:'logout'})
    dispatch({type:'LOGOUT'})
  },[])
  if(user){
    return <h1>Logging Out ...</h1>
  }
  return <Redirect to='/login'/>
}
const DEFAULT_CONFIG = {
    "tests" : [],
    "actions" : [],
    "datapoints" : [ 
        {
            "id" : "source",
            reciepes:{
                // eslint-disable-next-line
                value: "${source}",
                "tests":[]
            }
        }, 
        {
            "id" : "medium",
            reciepes:{
                // eslint-disable-next-line
                value: "${medium}",
                "tests":[]
            }
        }
    ],
    "utms" : [ 
        {
            "id" : "utm_source",
            "_id" : "5961f98a2edd74624930d3ce",
            "list" : [ 
                "source"
            ]
        }, 
        {
            "id" : "utm_medium",
            "_id" : "5961f98a2edd74624930d3cd",
            "list" : [ 
                "medium"
            ]
        }, 
        {
            "id" : "utm_campaign",
            "_id" : "5961f98a2edd74624930d3cc",
            "list" : []
        }, 
        {
            "id" : "utm_term",
            "_id" : "5961f98a2edd74624930d3cb",
            "list" : []
        }, 
        {
            "id" : "utm_content",
            "_id" : "5961f98a2edd74624930d3ca",
            "list" : []
        }
    ],
    "fields" : [ 
        {
            "title" : "Source",
            "id" : "source",
            "type" : "buttons",
            "fields" : [ 
                {
                    "text" : "Facebook",
                    "value" : "facebook"
                }, 
                {
                    "text" : "Google",
                    "value" : "google"
                }
            ],
            "hasOther" : false,
            "isTopRight" : false,
            "otherType" : "none"
        }, 
        {
            "title" : "Medium",
            "id" : "medium",
            "type" : "buttons",
            "fields" : [ 
                {
                    "text" : "CPC",
                    "value" : "cpc"
                }, 
                {
                    "text" : "Social Organic",
                    "value" : "so"
                }, 
                {
                    "text" : "Social Paid",
                    "value" : "sp"
                }
            ],
            "hasOther" : false,
            "isTopRight" : false,
            "otherType" : "none"
        }
    ]
};
const DEFAULTS = {
	BUILDER_URL:"https://app.utm-builder.com/design?test=9#Lemon",
	CONFIG:DEFAULT_CONFIG
};
const host = window.location.protocol + '//' + window.location.host;
const CONSTANTS = {
    PLANS: [
        {
            id: "PERSONAL",
            _id: "personal",
            linksIncluded: 50,
            stripeIds: {
                test: "price_1OBEKLGTICam6nlBfbVqvoO4",
                production: "price_1OBFYiGTICam6nlBbq47AyyT",
            },
            title: "Personal",
            price: 9,
            perks: [
                'Build your own tags',
                '50 links/month',
                'Google analytics compatible',
            ]
        },
        {
            id:'START_UP',
            _id: "startup",
            linksIncluded: 200,
            stripeIds: {
                test: "price_1OBEJlGTICam6nlBEoKoc7dR",
                production: "price_1OBFYQGTICam6nlBfXo0yFdl",
            },
            title: 'Startup',
            color: '#325EEA',
            price: 39,
            perks: [
                'Everything from Personal',
                '200 links/month',
                'Bitly integration',
                'Unlimited users'
            ]
        },
        {
            id:'ENTERPRISE',
            _id: "enterprise",
            linksIncluded: -1,
            stripeIds: {
                test: "price_1OBEIrGTICam6nlB952WZBfO",
                production: "price_1OBFY5GTICam6nlBHHBkmCAO",
            },
            title: 'Enterprise',
            color: '#FFC107',
            price: 89,
            perks: [
                'Everything from Startup',
                'Unlimited links/month',
                'Encrypted URL-tracking',
                'Optimization consultation',
                'Matomo and Adobe tracking',
                'Custom Parameters'
            ]
        },
        {
            id: 'CUSTOM',
            _id: "custom",
            linksIncluded: -1,
            title: 'Custom',
            color: '#DE4437',
            price: 'T.B.D',
            perks: [
                'Customised package?',
                'Implement your current tracking data?',
                'Need something out of the ordinary?',
            ]
        }
    ],
    CURR_SYM: {
        'eur': '€',
        'usd': '$'
    },
    OAUTH_CONFIG:{
        bitly:{
            auth_uri:'https://bitly.com/oauth/authorize',
            client_id:'62713fe4f12dba268b114df01c5f74b91dfdcdca'
        },
        google:{
            auth_uri:'https://accounts.google.com/o/oauth2/v2/auth',
            'client_id': '1020145984976-dfuch4vaut1v70a363qmu6po81qef79s.apps.googleusercontent.com',
            scope:['profile','email']
        },
        rebrandly: {
            auth_uri:"https://oauth.rebrandly.com/connect/authorize?redirect_uri="+encodeURIComponent(host + '/oauth/rebrandly/callback')+"&response_type=code&scope=rbapi%20offline_access&client_id=30dffcee-8642-4ea3-a712-d26171aa917a",
            "client_id":"30dffcee-8642-4ea3-a712-d26171aa917a",
            "redirect_uri":"https://utm-builder.com"
        },
        'microsoft':{
            scope:['openid','email','profile'],
            tenant_id:'7531e037-8892-4869-a9e7-480c2bd75e98',
            client_id:'244cc9f2-979d-47a9-943f-18ed10bc9541',
            response_mode:'query',
            state:'321',
            auth_uri:function(){
                const {tenant_id} = this;
                return `https://login.microsoftonline.com/${'common' || tenant_id}/oauth2/v2.0/authorize`
            }
        }
    }
}

export default function(key){
	return DEFAULTS[key] || CONSTANTS[key];
}
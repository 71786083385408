import { useState } from 'react'
import { useHistory } from "react-router";
import { Btn } from '../components/bootstrap'
import api from '../utils/api.ts';
import { useAppSelector } from '../redux/hooks';

const isStaging = window.location.origin !== 'https://app.utm-builder.com';

const REDIRECTS = {
  LOGOUT: '/logout',
  ADMIN: '/admin/'
}

export default function AccountTool() {
  const user = useAppSelector(state => state.userState.user)
  const [showDropdown, setShowDropdown] = useState()
  const history = useHistory()
  const toggleSettings = () => {
    history.push('#settings');
  }
  const toggleContact = () => {
    history.push('/support-form');
  }
  const redirect = (to) => history.push(to)
  const toggleActive = () => setShowDropdown(b => !b)
  const clickListItem = (clickType) => {
    return (e) => {
      e.stopPropagation()
      switch (clickType) {
        case 'logout':
          return redirect(REDIRECTS.LOGOUT);
        case 'admin':
          return redirect(REDIRECTS.ADMIN);
        case 'settings':
          toggleActive();
          return toggleSettings();
        case 'contact':
          toggleActive()
          return toggleContact()
        default:
          console.warn('INVALID clickType', clickType)
          break;
      }
    }
  }
  return <div className="accountTool" onClick={toggleActive}>
    <div className="userSettings">
      {getInitials(user)}
    </div>
    <span className="fas fa-caret-down" />
    {showDropdown && <div className="dropdown-menu show">
      <DropList onClick={clickListItem('settings')} i='cog'> Account Settings</DropList>
      <DropList onClick={clickListItem('contact')} i='envelope'> Contact Us</DropList>
      <DropList onClick={clickListItem('logout')} i='sign-out-alt'> Logout</DropList>
      {user.admin && <DropList onClick={clickListItem('admin')} i='bullhorn'> Admin Area</DropList>}
      {isStaging && <DeleteMe />}
    </div>}
  </div>
}
function DropList(props) {
  const { onClick, i, children } = props;
  return <li className="dropdown-item" onClick={onClick}>
    {i && <span className={"fas fa-" + i} />} {children}
  </li>
}
const DELETE_STLYE = {
  margin: 0,
  overflow: 'visible',
  padding: '10px 15px',
  lineHeight: '1.42857143',
  fontSize: '13px',
  width: '175px',
  height: '38px',
  // margin-top: 20px;
  // margin-right: 40px;
}
function DeleteMe() {
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()
  const deleteMe = async e => {
    setLoading(true)
    let res = await api.admin.users('delete', {})
    if (!res.error) {
      history.push('/logout')
    }
  }
  return <li className="dropdown-item" onClick={deleteMe}>
    <Btn danger style={DELETE_STLYE} disabled={isLoading}>
      DELETE MY USER (Admin)
    </Btn>
  </li>
}

function getInitials(user) {
  const { name, email } = user; // ensure that there is a string as name isnt always needed
  const title = name || email || 'John Doe';
  const parts = title.split(/\s|\.|'/)
  return parts.map(function(name) {
    return name && name[0]
  }).join('').substring(0, 3)
}





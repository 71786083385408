import { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";
import { store as reduxStore } from "./redux/store";
import Routes from "./routes/index";


function ProvidersSetup(props: PropsWithChildren<{}>) {
  return (
    <Provider store={reduxStore}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>{props.children}</BrowserRouter>
      </DndProvider>
    </Provider>
  );
}


export default function App() {
  return (
    <ProvidersSetup>
      <Routes />
    </ProvidersSetup>
  );
}

import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Address, Stripe } from "@stripe/stripe-js";
import { CONSTS } from "../constants/index";
import api from "../utils/api";
import { paySubscription } from "../utils/stripe";
const PLANS: UTMPlan[] = CONSTS("PLANS");
export interface CardSource {
  id: string;
  name: string;
  last4: string;
  brand: string;
  isActiveSource: boolean;
}
export interface StripePlan {
  id: string;
  nickname?: string;
  interval: string;
  interval_count: number;
  amount: number;
  currency: string;
}
export interface Coupon {
  id: string;
  percent_off: number;
  amount_off: number;
  code: string;
}
export interface UTMPlan {
  id: string;
  _id: string;
  linksIncluded: number;
  stripeIds: {
    test: string;
    production: string;
  };
  title: string;
  color: string;
  price: number;
  perks: string[];
  stripe?: StripePlan;
}

interface StripePricing extends StripePlan {
  netPrice: number;
  grossPrice: number;
  discount?: number;
  discountDesc?: string;
}
type Step = "CHOOSE_PLAN" | "CONFIRM" | "CUSTOM" | "INVOICE_DETAILS" | "DONE";

interface SliceState {
  stripePlans: StripePlan[];
  step: Step;
  billing_details: {
    shipping: {
      address: Address;
      name: string;
    };
    tax_info: {
      tax_id: string;
      type: string;
    };
  };
  promoCode: string;
  coupon?: Coupon;
  source?: CardSource;
  card_holder: string;
  planDesc?: UTMPlan;
  plan?: string;
  stripePlan?: string;
  pricing?: StripePricing;
  createSubscription: {
    loading: boolean;
    error?: string;
    data?: string;
    stage?: string;
  };
}

export type UpgradeState = SliceState;

const INITIAL_STATE: SliceState = {
  stripePlans: [],
  step: "CHOOSE_PLAN",
  billing_details: {
    shipping: {
      address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      name: "",
    },
    tax_info: {
      tax_id: "",
      type: "",
    },
  },
  promoCode: "",
  coupon: undefined,
  source: undefined,
  card_holder: "",
  planDesc: undefined,
  plan: undefined,
  pricing: undefined,
  createSubscription: {
    loading: false,
    error: undefined,
    data: undefined,
    stage: undefined,
  },
};
function initState(): SliceState {
  return { ...INITIAL_STATE };
}

function calculatePricing(
  planDesc: SliceState["planDesc"],
  coupon: SliceState["coupon"]
): StripePricing | undefined {
  if (!planDesc) {
    return undefined;
  }
  const {
    id = "unknown",
    interval = "month",
    interval_count = 1,
    amount = planDesc.price * 100,
    currency = "eur",
  } = planDesc.stripe || {};
  const unitCost = Math.round(amount / interval_count);
  const grossPrice = unitCost;
  let netPrice = grossPrice;
  let discount = undefined;
  let discountDesc = undefined;
  if (coupon) {
    const { percent_off, amount_off } = coupon;
    discount = 0;
    if (percent_off) {
      discountDesc = percent_off + "%";
      discount = (grossPrice / 100) * percent_off;
    } else if (amount_off) {
      discount = amount_off;
    }
    netPrice = grossPrice - discount;
  }
  const pricing = {
    id,
    amount,
    netPrice: netPrice / 100,
    grossPrice: grossPrice / 100,
    discount: !discount ? undefined : discount / 100,
    discountDesc,
    interval_count,
    interval,
    currency,
  };
  return pricing;
}

export const getStripePlans = createAsyncThunk(
  "upgrade/getStripePlans",
  async (_, thunkAPI) => {
    //@ts-ignore
    const upgrade: SliceState = thunkAPI.getState().upgrade as SliceState;
    if (upgrade.stripePlans.length) {
      return;
    }
    const { data } = await api.cached.stripe<StripePlan[]>("listPlans", {});
    return data;
  }
);
function stripeDef(subscription: Subscription) {
  console.log(subscription);
  const { plan } = subscription;
  const planDesc = PLANS.find((p) => p.id === plan);
  if (!planDesc) {
    throw new Error("PLAN NOT FOUND");
  }
  const { _id, linksIncluded } = planDesc;
  return {
    paymentMethod: "stripe",
    collection_method: "charge_automatically",
    ...subscription,
    plan: _id,
    linksIncluded,
  };
}
interface Subscription {
  isEnterprise: boolean;
  stripePlanId: string;
  profileId: string;
  userId: string;
  plan?: string;
  promoCode?: string;
  linksIncluded?: number;
}

const CREATE_SUBSCRIPTION = createAction<SliceState["createSubscription"]>(
  "upgrade/createSubscription"
);
export const createStripeSubscription = createAsyncThunk(
  CREATE_SUBSCRIPTION.type,
  async (params: { stripe: Stripe; subscription: Subscription }, thunkAPI) => {
    const { stripe } = params;
    const subscription = stripeDef(params.subscription);
    // @ts-ignore
    const source = (thunkAPI.getState()?.upgrade as SliceState).source;
    const createDis = (payload: SliceState["createSubscription"]) =>
      thunkAPI.dispatch({
        type: CREATE_SUBSCRIPTION.type,
        payload,
      });
    createDis({ loading: true, stage: "SUBSCRIPTION" });
    const subRes = await api.subscriptions("create", subscription);
    if (subRes.error) {
      return createDis({
        loading: false,
        error: subRes.error,
        stage: "SUBSCRIPTION",
      });
    }
    const subscriptionId = subRes.data._id;
    createDis({ loading: true, stage: "STRIPE" });
    const payRes = await paySubscription(stripe, { subscriptionId, source });
    if (payRes.error) {
      return createDis({
        loading: false,
        error: payRes.error,
        stage: "STRIPE",
      });
    }
    return createDis({
      loading: false,
      data: payRes.data,
      stage: "STRIPE",
    });
  }
);

const slice = createSlice({
  name: "upgrade",
  initialState: initState(),
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getStripePlans.fulfilled, (state, action) => {
      state.stripePlans = action.payload || [];
    });
    builder.addCase(CREATE_SUBSCRIPTION, (state, action) => {
      console.log(action);
      state.createSubscription = action.payload;
      if (action.payload?.data) {
        state.step = "DONE";
      }
    });
  },
  reducers: {
    setStep(state, params: PayloadAction<Step>) {
      state.step = params.payload;
    },
    upgradeReset() {
      return initState();
    },
    setSource(state, params: PayloadAction<CardSource>) {
      state.source = params.payload;
      state.createSubscription = {
        loading: false,
        error: undefined,
        data: undefined,
        stage: undefined,
      };
      state.step = "CONFIRM";
    },
    setCardDetails(state, params: PayloadAction<string>) {
      state.card_holder = params.payload;
    },
    setBillingDetails(
      state,
      params: PayloadAction<SliceState["billing_details"]>
    ) {
      state.billing_details = params.payload;
    },
    setPlan(state, params: PayloadAction<string>) {
      let plan = "Custom Request";
      console.log(PLANS);
      const planDesc = PLANS.find((p) => p.id === params.payload);
      if (!planDesc) {
        throw new Error("PLAN NOT FOUND");
      }
      const stripeDesc = (state.stripePlans || []).find(
        (p) =>
          p.id === plan ||
          p.nickname === plan ||
          [planDesc.stripeIds.test, planDesc.stripeIds.production].includes(
            p.id
          )
      );
      state.planDesc = {
        ...planDesc,
        stripe: stripeDesc,
      };
      state.pricing = calculatePricing(state.planDesc, state.coupon);
      state.step = params.payload === "CUSTOM" ? "CUSTOM" : "INVOICE_DETAILS";
      state.plan = params.payload;
      state.stripePlan = plan;
    },
    setCoupon(state, params: PayloadAction<Coupon>) {
      state.coupon = params.payload;
      state.pricing = calculatePricing(state.planDesc, state.coupon);
    },
    setPromoCode(state, params: PayloadAction<string>) {
      state.promoCode = params.payload;
    },
  },
});
export const actions = slice.actions;
export default slice.reducer;

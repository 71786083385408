function initState(){
	const state = {}
	return state;
}

function updateSubscription(state,payload){
	let { subscriptionId:id } = payload
	let sub = state[id] || {};
	sub = {...sub,...payload}
	return Object.assign({},state,{[id]:sub})
}
function updateOnSuccess(state,payload){
	let { subscriptionId:id ,data} = payload
	if(!data) return state// if there isnt data dont change state
	let sub = state[id] || {};
	sub = {...sub,...payload}
	return Object.assign({},state,{[id]:sub})
}
function subscriptions(state = initState(),action){
	const {type,data} = action;
	switch(type){
		case 'LOGOUT':
			return initState()
		case 'GET_SUB':
		case 'UPDATE_SUBSCRIPTION':
		case 'UPDATE_SUB':
			return updateSubscription(state,data)
		case 'LINK_PROFILE':
		case 'SYNC_STRIPE':
			return updateOnSuccess(state,data)
		default:
			return state
	}
}	

export default subscriptions


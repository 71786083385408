import { Redirect, Route, Switch, RouteProps, useHistory, useLocation } from "react-router-dom";
import asyncComponent from "../components/asyncComponent";
import { NavBar, Alerts } from "../layout";
import DashSidebars from "../layout/DashSidebars";
import EnsureLoginContainer from "../layout/EnsureLoginContainer";
import Logout from "../Auth/Logout";
import {
  ApiListener,
  ExtenternalRedirect,
  FullIframe,
  HashRoute,
  IframePath,
  PagePathListener,
} from "./utils";
import { useAppSelector } from "../redux/hooks";

const Terms = asyncComponent(
  () => import(/* webpackChunkName: "Terms"*/ "./Terms")
);
const ChatBot = asyncComponent(
  () => import(/* webpackChunkName: "ChatBot"*/ "./ChatBot")
);
const UpdateCard = asyncComponent(
  () => import(/* webpackChunkName: "UpdateCard"*/ "./UpdateStripeCard")
);
const Tutorial = asyncComponent(
  () => import(/* webpackChunkName: "Tutorial"*/ "../Tutorial/Tutorial")
);
const Fallback = asyncComponent(
  () => import(/* webpackChunkName: "Fallback"*/ "./Fallback")
);
const TestURL = asyncComponent(
  () => import(/* webpackChunkName: "Fallback"*/ "./TestURL")
);
const PreviewWindow = asyncComponent(
  () => import(/* webpackChunkName: "Fallback"*/ "./PreviewWindow")
);
const UserManagement = asyncComponent(
  () => import(/* webpackChunkName: "UserManagement"*/ "./UserManagement")
);
const PassReset = asyncComponent(
  () => import(/* webpackChunkName: "PassReset" */ "./PassReset")
);
const QRCode = asyncComponent(
  () => import(/* webpackChunkName: "QRCode" */ "./QRCode")
);
const SignUp = asyncComponent(
  () => import(/* webpackChunkName: "SignUp" */ "./SignUp")
);
const Upgrade = asyncComponent(
  () => import(/* webpackChunkName: "Upgrade" */ "../Upgrade/index")
);
const Dashboard = asyncComponent(
  () => import(/* webpackChunkName: "Dasboard" */ "./Dashboard/")
);
const Settings = asyncComponent(
  () => import(/* webpackChunkName: "Settings" */ "./Settings/")
);
const ExtensionLoginComplete = asyncComponent(
  () => import(/* webpackChunkName: "ExtensionLoginComplete" */ "./ExtensionLoginComplete")
);
const Editor = asyncComponent(
  () => import(/* webpackChunkName: "Editor" */ "./Editor")
);
const Templates = asyncComponent(
  () => import(/* webpackChunkName: "Templates" */ "./Templates")
);
const Invoices = asyncComponent(
  () => import(/* webpackChunkName: "Invoices" */ "./Invoices")
);
const ShortenerDomains = asyncComponent(
  () => import(/* webpackChunkName: "Invoices" */ "./ShortenerDomains")
);
const OAuthProvider = asyncComponent(
  () => import(/* webpackChunkName: "OAuthProvider" */ "./OAuthProvider")
);
const History = asyncComponent(
  () => import(/* webpackChunkName: "History" */ "./History/index")
);
const Admin = asyncComponent(
  () => import(/* webpackChunkName: "Admin" */ "../Admin/")
);
const FrameSignUp = asyncComponent(
  () => import(/* webpackChunkName: "Frame_SignUp" */ "./Frame_SignUp")
);
const FrameBuilder = asyncComponent(
  () => import(/* webpackChunkName: "Frame_Builder" */ "./Frame_Builder")
);
const BasicBuilder = asyncComponent(
  () => import(/* webpackChunkName: "BasicBuilder" */ "./BasicBuilder")
);
const Unsubscribe = asyncComponent(
  () => import(/* webpackChunkName: "Unsubscribe" */ "./Unsubscribe")
);
const ExtensionOAuth = asyncComponent(
  () => import(/* webpackChunkName: "ExtensionOAuth" */ "./ExtensionOAuth")
);

function DashView(props: RouteProps) {
  return (
    <DashSidebars {...props}>
      <Switch>
        <Route path="/app/dash" component={Dashboard} />
        <Route path="/app/invoices" component={Invoices} />
        <Route path="/app/shortener-domains" component={ShortenerDomains} />
        <Route path="/app/user-management/" component={UserManagement} />
        <Redirect path="/app/user-managment/" to="/app/user-management/" />
        <Route path="/app/history" component={History} />
        <Redirect path="/app/designer/:_id" to="/app/editor/:_id" />
        <Route path="/app/editor/:_id">
          <Editor isDesigner />
        </Route>
        <Route path="/app/new/:_id">
          <Editor isDesigner />
        </Route>
        <Redirect path="/app" to="/app/dash" />
      </Switch>
    </DashSidebars>
  );
}

function AppView() {
  return (
    <EnsureLoginContainer level="basic">
      <Switch>
        <Redirect path="/app/profiles/:_id" to="/app/designer/:_id" />
        <Redirect
          path="/app/profiles/:_id/embed"
          to="/app/conventions/:_id/embed"
        />
        <Redirect
          path="/app/profiles/:_id/view"
          to="/app/conventions/:_id/view"
        />
        <Route path="/app/conventions/:_id/embed">
          <FrameBuilder profileType="profile" />
        </Route>
        <Route path="/app/conventions/:_id/view">
          <FrameBuilder profileType="profile" />
        </Route>
        <Route path="/app/upgrade/:_id" component={Upgrade} />
        <Route
          path="/app/subscriptions/:_id/update-payment"
          component={UpdateCard}
        />
        <Route path="/app/basic-builder" component={BasicBuilder} />
        <Route path="/app/tutorial/:id" component={Tutorial} />
        <Redirect exact path="/app/tutorial" to="/app/tutorial/0" />
        <Route path="/app">
          <DashView />
        </Route>
      </Switch>
    </EnsureLoginContainer>
  );
}

const EXCLUDE_NAV = [
  /\/pass-reset\//,
  /\/request-reset/,
  "/promo/sign-up",
  /\/tutorial*\//,
  /\/upgrade*\//,
  "/app/basic-builder",
  "/frame/sign-up",
  /^\/templates*\//,
  /\/embed/,
  /login|sign-up|create-qrcode|reset|email\/unsubscribe/,
];
function SupportForm() {
  const user = useAppSelector(s => s.userState?.user)
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search || "")
  if (user?.email) {
    searchParams.set('email', user.email)
  }
  const href = "https://share.hsforms.com/1RYr7q79OSd-TSNbabc-Snw2dnys?" + searchParams.toString()
  return <FullIframe
    href={href}
  />
}

function Routes() {
  return (
    <>
      <NavBar exclude={EXCLUDE_NAV} />
      <ApiListener />
      <PagePathListener />
      <Switch>
        <Redirect exact path="/" to="/app/dash" />
        <Route path="/test-url" component={TestURL} />
        <Route path="/preview-window" component={PreviewWindow} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/fallback-app-version-change" component={Fallback} />
        <Route path="/login" component={SignUp} />
        <Route path="/admin-login" component={SignUp} />
        <Route path="/logout" component={Logout} />
        <Route path="/request-reset" component={SignUp} />
        <Route path="/terms" component={Terms} />
        <Route path="/chat-bot" component={ChatBot} />
        <ExtenternalRedirect
          path="/privacy-policy"
          href="https://www.utm-builder.com/privacy-policy/"
        />
        <IframePath
          path="/book-call"
          href="https://app.hubspot.com/meetings/info4031"
        />
        <Route path="/support-form" component={SupportForm} />
        <Route path="/pass-reset/:resetCode" component={PassReset} />
        <Route path="/create-qrcode" component={QRCode} />
        <Route path="/email/unsubscribe" component={Unsubscribe} />
        <Route path="/oauth/:provider/callback" component={OAuthProvider} />
        <Route path="/extension-oauth/:provider" component={ExtensionOAuth} />
        <Route exact path="/templates" component={Templates} />
        <Redirect path="/template/:_id" to="/templates/:_id" />
        <Route path="/templates/:_id">
          <FrameBuilder profileType="template" />
        </Route>
        <Route path="/frame/sign-up" component={FrameSignUp} />
        <Route path="/promo/sign-up" component={FrameSignUp} />
        <Route path="/admin">
          <EnsureLoginContainer level="admin">
            <Admin />
          </EnsureLoginContainer>
        </Route>
        <Route path="/app">
          <HashRoute hash="#settings" component={Settings} />
          <HashRoute
            hash="#extension-login"
            component={ExtensionLoginComplete}
          />
          <AppView />
        </Route>
        <Redirect path="/" to="/app/dash" />
      </Switch>
      <Alerts />
    </>
  );
}

export default Routes;

import { event } from '../utils/gtm.ts';
export const LSTORAGE_KEY = 'userData'
function loadStoredUser() {
	let user = JSON.parse(localStorage.getItem(LSTORAGE_KEY));
	user.profiles = user.profiles || []
	user.name = user.name || ''
	const {
		tutorialStatus: questionaire,
		designerTutorialStatus: editor
	} = user
	user.onboarding = user.onboarding || {
		questionaire, editor
	}
	if (!user) {
		throw new Error('NO_STORED_USER')
	}
	return user
}
function initState() {
	let user = null
	let users = {};
	let isAdmin = false
	try {
		user = loadStoredUser()
		isAdmin = user.admin;
		users[user._id] = { data: user };
	} catch (e) { }
	const state = {
		isAdmin,
		isUserLoading: false,
		userError: null,
		user,
		users
	}
	return state;
}
function _updateUsers(users, payload) {
	const { userId } = payload;
	const oldUser = users[userId] || { loading: false, error: null, data: null };
	return {
		...users,
		[userId]: { ...oldUser, ...payload }
	}
}

function setUser(state, user) {
	let isAdmin = false;
	let users = state.users;
	if (user) {
		user.profiles = user.profiles || [];
		user.name = user.name || ''
		isAdmin = user.admin;
		users = _updateUsers(users, { userId: user._id, data: user })
		var val = undefined;
		try {
			val = JSON.stringify(user);
		} catch (e) { };
		localStorage.setItem(LSTORAGE_KEY, val);
	} else {
		localStorage.removeItem(LSTORAGE_KEY)
	}
	return { ...state, user, isAdmin, users }
}

function addUserProfile(state, profile) {
	let { user } = state;
	user.profiles = user.profiles.concat([profile]);
	return setUser(state, user)
}

function updateUser(state, payload) {
	const { data, loading, error, userId, request, isOptimistic } = payload
	const { user } = state
	state = {
		...state,
		isUserLoading: loading,
		userError: error,
		users: _updateUsers(user, payload)
	}
	if (user?._id !== userId) {
		return state
	}
	// was getting an error where users didnt have a user when logged in - TODO figreu this out
	if (data) {
		return setUser(state, data); // overwrite the user data
		// TODO syncing issues with this sloppy optimistic update thing ?
	}
	if (isOptimistic) { // optimistic updates dont wait for the response
		return {
			...state,
			user: { name: '', ...user, ...request.update }
		};
	}
	return state
}

function userState(state = initState(), action) {
	const { type, data } = action;
	switch (type) {
		case 'GET_ME':
		case 'GET_USER':
		case 'UPDATE_USER': {
			return updateUser(state, data)
		}
		case 'setUser':
			return setUser(state, data)
		case 'COMPLETE_LOGIN':
			event('login')
			return setUser(state, data)
		case 'COMPLETE_SIGNUP':
			event('signUp')
			return setUser(state, data)
		case 'LOGOUT':
			event('logout');
			return setUser(state, null)
		case 'addUserProfile':
			return addUserProfile(state, data)
		default:
			return state
	}
}

export default userState

